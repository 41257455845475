// main
import { useState, useEffect, useRef } from "react";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";

// styles
import "./App.css";
import "./fonts.css";
import "./toggle.css";
import './neon-sign.css';
import "./logo.css";
import 'font-awesome/css/font-awesome.min.css';

// 3rd deps
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';
import { ReactInternetSpeedMeter } from "react-internet-meter";
import Snowfall from 'react-snowfall';
import Modal from 'react-modal';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Popup from 'reactjs-popup';
import ReactCountryFlag from "react-country-flag";
import toast, { Toaster } from 'react-hot-toast';

// import { Transition } from "react-transition-group";
// import CookieConsent, { Cookies } from "react-cookie-consent";
// import PulsingBadge from "./PulsingBadge";

// custom component files
import Footer from './components/footer/Footer';
import FadeIn from "./FadeIn";
import ConsolePopup from "./ConsolePopup";
import ExternalLinkConfirm from "./extra-components/ExternalLinkConfirm";
import ExternalLinkPopupFAIcon from "./extra-components/ExternalLinkPopupFAIcon";
import HeaderLogo from "./components/header/HeaderLogo";
import HeaderToggle from "./components/header/HeaderToggle";
import PrivacyPolicy from "./extra-components/PrivacyPolicy";
import CookiePolicy from "./extra-components/CookiePolicy";
import TabModal from "./TabModal";
// import HTTPReq from "./extra-components/HTTPReq";

// var html2canvas = require('html2canvas');

let apiServer;
if (window.location.host === "localhost:3000") {
  apiServer = "http://127.0.0.1:5001"
} else {
  apiServer = "https://netof.me"
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgb(0, 0, 0, 1)',
    boxShadow: "0px 0px 25px 0.5px #222e4c",
    maxWidth: '650px',
    width: '80%',
    borderRadius: '18px',
    fontFamily:'Chakra Petch',
    border: '0px',
    zIndex: 9999999,
  },
  overlay: {
    backgroundColor: 'rgb(15, 22, 41, 0.75)',
    zIndex: 9999999,
  },
};

const customStylesMap = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgb(0, 0, 0, 1)',
    boxShadow: "0px 0px 25px 0.25px #222e4c",
    width: '80%',
    borderRadius: '18px',
    fontFamily:'Chakra Petch',
    border: '0px',
    animation: '1s ease-in',
    zIndex: 9999999,
  },
  overlay: {
    backgroundColor: 'rgb(15, 22, 41, 0.75)',
    zIndex: 9999999,
  },
};

let previousSearches = [];
// let previousSearches_temp = [JSON.parse(window.localStorage.getItem("previousSearchesObj"))];
// if (previousSearches_temp.length > 1) {

// }
let previousSearchesObj = [{}];

// function storePrevSearchObj() {
//   window.localStorage.setItem("previousSearchesObj", JSON.stringify(previousSearchesObj));
//   console.log(JSON.parse(window.localStorage.getItem("previousSearchesObj")));
// }

// function storePrevSearch() {
//   window.localStorage.setItem("previousSearches", JSON.stringify(previousSearches));
//   console.log(JSON.parse(window.localStorage.getItem("previousSearches")));
// }

export function HistoryTableCell(props) {
  const [isHistoryCopied, setHistoryIsCopied] = useState();

  function isHostname(value) {
    // check if value is an IP address
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (ipRegex.test(value)) {
      return false;
    }
    // check if value is a hostname 
    const hostnameRegex = /^([a-zA-Z0-9-]{1,}\.)+[a-zA-Z]{2,}$/;
    if (hostnameRegex.test(value)) {
      return true;
    }
    return false;
  }

  function handleSearchAgainClick(index) {
    document.getElementById("search-field").value = `${previousSearchesObj[index].domain}`;
    document.getElementById("search-field").focus();
  }

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyPrevious = (data) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(data)
      .then(() => {
        // If successful, update the isCopied state value
        setHistoryIsCopied(true);
        setTimeout(() => {
          setHistoryIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div id="history-table-wrapper">
      <table>
        <tbody>
          <tr id={`tr-${props.index}`} style={{backgroundColor: "black"}} className="table-on-hover">
            <td className="history-cell" style={{color: "#2b3a61"}}>{props.time}</td>
            <td className="history-cell">{props.domain}</td>
            <td className="history-cell">
              <span style={{textAlign: "right"}}>
                <span style={{color: "#2b3a61", cursor: "pointer"}}>
                  <i onClick={() => (handleSearchAgainClick(props.index))} data-tooltip-id={`tooltip`} data-tooltip-content="Fill search field" className="fa fa-solid fa-arrow-right"></i>
                </span>
                <span onClick={() => handleCopyPrevious(props.domain)} style={{paddingLeft: "13px", color: "#2b3a61", cursor: "pointer"}}>
                  {isHistoryCopied ? 
                  (<i data-tooltip-id={`tooltip`} data-tooltip-content="Copied to clipboard!" className="fa fa-regular fa-check"></i>) 
                  : (<i data-tooltip-id={`tooltip`} data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i>)}
                </span>
                <span style={{paddingLeft: "13px", color: "#2b3a61", cursor: "pointer"}}>
                  <Popup
                    trigger={
                      <i id={`vt-link-${props.index}`} data-tooltip-id={`tooltip`} data-tooltip-content="Open in VirusTotal" className="fa fa-solid fa-shield-virus"></i>
                    }
                    position={['bottom center']}
                    on={['click']}
                    nested
                  >
                    <ExternalLinkConfirm 
                      link={`https://www.virustotal.com/gui/search/${props.domain}`} 
                      id={`vt-link-${props.index}`}
                    />
                  </Popup>
                </span>
                <span style={{paddingLeft: "13px", color: "#2b3a61", cursor: "pointer"}}>
                  <Popup
                    trigger={
                      <i id={`st-link-${props.index}`} data-tooltip-id={`tooltip`} data-tooltip-content="Open in SecurityTrails" className="fa fa-solid fa-road"></i>
                    }
                    position={['bottom center']}
                    on={['click']}
                    nested
                  >
                    <ExternalLinkConfirm 
                      link={isHostname(props.domain) ? `https://securitytrails.com/domain/${props.domain}/history/a` : `https://securitytrails.com/list/ip/${props.domain}`} 
                      id={`st-link-${props.index}`}
                    />
                  </Popup>
                </span>
                <span style={{paddingLeft: "13px", color: "#2b3a61", cursor: "pointer"}}>
                  <Popup
                    trigger={
                      <i id={`google-history-link-${props.index}`} data-tooltip-id="tooltip" data-tooltip-content="Search on Google" style={{ fontSize: "17px", cursor: "pointer"}} className="fa-brands fa-google"></i>
                    }
                    position={['bottom center']}
                    on={['click']}
                    nested
                  >
                    <ExternalLinkConfirm 
                      link={`https://google.com/search?q=${props.domain}`} 
                      id={`google-history-link-${props.index}`}
                    />
                  </Popup>
                </span>
              </span>
            </td>
           </tr>
        </tbody>
      </table>
    </div>
  )
}

export function HistoryDropdown(props) {
  // previousSearches, previousSearchesObj
  return (
    <span style={{paddingLeft: "8px", overflowY: "hidden"}}>
      <Popup
        trigger={
          <i id="history-icon" data-tooltip-id="tooltip" data-tooltip-content={"Show search history (CTRL + H)"} style={{cursor: "pointer"}} className="fa-solid fa-clock-rotate-left"></i>
        }
        position={['bottom center']}
        closeOnDocumentClick
        nested
      >
        <div>
          {previousSearches.length > 0  ?
          previousSearchesObj.map((data, index) => (
            <>
              <HistoryTableCell 
                index={index} 
                domain={data.domain}
                time={data.time}
              />
            </>
          ))
          :
          <div>
            <div style={{maxWidth: "250px", paddingTop: "10px"}} id={"history-table-wrapper"}>
              <div style={{textAlign: "center", paddingBottom: "7px"}}>
                <div className="rotate-text-90">
                  <span style={{fontSize: "52px", color: "#2b3a61"}}>:(</span>
                </div>
                <br/>
                <span style={{padding: "7.5px", color: "#2b3a61"}}><i>Ah heck, there's nothing here!</i></span>
              </div>
            </div>
          </div>
          }
        </div>
      </Popup>
    </span>
  )
}




function App() {
  const textArray = [
    'So,',
    'Well,',
    'Okay,',
    'Dang,',
    'Hi,',
    'Hey,',
    'Hello,',
    'Look,',
    'Wow,',
    'Nice,',
    'Ooo,',
    'Woah,',
    'Shoot,',
  ];

  const [loading, setLoading] = useState("");
  const [ip, setIP] = useState("");
  const [hostname, setHostname] = useState("");
  const [city, setCity] = useState("");
  const [org, setOrg] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [loc, setLoc] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const [userAgent, setUserAgent] = useState("");
  const [showUserAgent, setShowUserAgent] = useState("");

  const [letItSnow, setLetItSnow] = useState();

  const [randomNumber, setRandomNumber] = useState("");

  const [isIpCopied, setIpIsCopied] = useState(false);
  const [isOrgCopied, setOrgIsCopied] = useState(false);
  const [isMtaCopied, setMtaIsCopied] = useState(false);
  const [isUserAgentCopied, setUserAgentIsCopied] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const [infoModelIsOpen, setInfoIsOpen] = useState(false);
  const [extraModelIsOpen, setExtraIsOpen] = useState(false);
  // const [consoleIsOpen, setConsoleIsOpen] = useState(false);

  const [requesedTime, setRequestedTime] = useState("");

  const [googleLink, setGoogleLink] = useState("");

  const [checkSpeed, setCheckSpeed] = useState("");
  const [wantCheckSpeed, setWantCheckSpeed] = useState(false);
  const [speedtestURL, setSpeedtestURL] = useState("");
  const [browser, setBrowser] = useState(false);
  const [osFamily, setOsFamily] = useState(false);

  const [showSearch, setShowSearch] = useLocalStorage("showSearch",false);

  const [whosIP, setWhosIP] = useState("your");
  const [domainName, setDomainName] = useState();
  const [whosOtherStuff, setWhosOtherStuff] = useState("your");

  const [whoisDomainName, setWhoisDomainName] = useState();
  const [whoisCreationDate, setWhoisCreationDate] = useState();
  const [whoisExpireDate, setWhoisExpireDate] = useState();
  const [whoisOrg,setWhoisOrg] = useState();
  const [whoisUpdatedDate,setWhoisUpdatedDate] = useState();
  const [whoisRegistrar,setWhoisRegistrar] = useState();
  const [whoisServer,setWhoisServer] = useState();

  const [whoisEmails,setWhoisEmails] = useState();
  const [whoisDNSSec,setWhoisDNSSec] = useState();
  const [whoisDomainProtection,setWhoisDomainProtection] = useState();

  const [whoisResponse, setWhoisResponse] = useState();

  // const [showNothingChecked, setShowNothingChecked] = useState(false);

  const [showTableChecked, setShowTableChecked] = useLocalStorage("showTableChecked",false);
  const [showWhoisTable, setShowWhoisTable] = useLocalStorage("showWhoisTable",false);
  // const [showMainIPChecked, setShowMainIPChecked] = useLocalStorage("showMainIPChecked","");
  const [showMainIPChecked, setShowMainIPChecked] = useState(true);

  // const doh = require('dohjs');

  function openInfoModal() {
    setInfoIsOpen(true);
  }

  function afteropenInfoModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
    document.body.style.overflow = "hidden";
  }

  function closeInfoModal() {
    setInfoIsOpen(false);
    document.body.style.overflow = "overlay";
  }

  function openMapModal() {
    setExtraIsOpen(true);
  }

  function afteropenMapModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
    // disableScroll();
    document.body.style.overflow = "hidden";
  }
  //   function afteropenConsoleModal() {
  //   // references are now sync'd and can be accessed.
  //   // subtitle.style.color = '#f00';
  //   // disableScroll();
  //   document.body.style.overflow = "hidden";
  // }

  // function closeConsoleModal() {
  //   setConsoleIsOpen(false);
  //   document.body.style.overflow = "overlay";
  // }
  function closeExtraModal() {
    setExtraIsOpen(false);
    document.body.style.overflow = "overlay";
  }

  let link;
  if (window.location.href === "http://localhost:3000/") {
    link = `${apiServer}/api/v1/ip/1.0.0.1`
  } else {
    link = `${apiServer}/api/v1/ip/me`
  }

  const queryParameters = new URLSearchParams(window.location.search)
  let searchParam = queryParameters.get("search")
  const showParam = queryParameters.get("show")
  let toastId;

  const getData = async () => {
    setLoading(true);
    
    // const [searchParam, setSearchParam] = useState("")
    // let searchParam = queryParameters.get("search")
    

    if (searchParam !== null) {
        setIP(false)
        handleURLSubmit(searchParam)
    } else {
        // get time
        var time = new Date();
        setRequestedTime(time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))

        // set user agent
        setUserAgent(window.navigator.userAgent);

        // set random num for greeting array
        setRandomNumber(Math.floor(Math.random()*textArray.length));

        // -> ip request for current ip
        let res = await axios.get(link)
        .catch(function (error) {
            if (error.response.status === 429) {
              toast.error(`Ah heck, you've exceeded the rate limit!`, {
                id: toastId,
              })} else if (error.response.status === 404) {
                toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
              })} else {
                toast.error(`Ah heck, we've got an error!`, {
                    id: toastId,
              })}
            });

            // console.log(res)
        if (res) {
            res = res.data
            // set response
            setIP(res.ip);
            setHostname(res.hostname);
            setOrg(res.org);
            setCity(res.city);
            setRegion(res.region);
            setCountry(res.country);
            setLoc(res.loc);
            setLat((res.loc).split(',')[0])
            setLong((res.loc).split(',')[1])

            // let it snow in December, January an February
            const month = (new Date().getMonth() + 1)
            if (month === 12 || month === 1 || month === 2) {
                if ((new Date().getDay() + 1) % 2 !== 0) { // only snow on odd numbered days
                setLetItSnow(true)
                }
            } else {
                setLetItSnow(false)
            }

            // setBrowser
            let browserInfo = navigator.userAgent;
            if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
                setBrowser('Opera');
            } else if (browserInfo.includes('Edg')) {
                setBrowser('Microsoft Edge');
            } else if (browserInfo.includes('Chrome')) {
                setBrowser('Google Chrome');
            } else if (browserInfo.includes('Safari')) {
                setBrowser('Safari');
            } else if (browserInfo.includes('Firefox')) {
                setBrowser('Mozilla Firefox');
            } else {
                setBrowser(false);
            }

            // (try to) get OS information
            var platform = require('platform');
            if (platform.os.family === "OS X") {
                setOsFamily('macOS')
            } else {
                setOsFamily(platform.os.family)
            }

            setGoogleLink(`https://google.com/search?q=${res.org}`)
            setLoading(false);
        }
    }
  };

  useEffect(() => {

    getData();

  }, []);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }


  const refresh = () => {
    window.location.assign('/')
    // window.location.reload(true)
  }

  const handleSearchClick = () => {
    if (showSearch === false) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }

  const handleMainIPCheckChange = () => {
    if (showMainIPChecked === false) {
      setShowMainIPChecked(true);
      document.getElementById("ipinformation-section").style.display = "inline";
      // document.getElementById("content").style.marginTop = "50%"
    } else {
      setShowMainIPChecked(false);
      document.getElementById("ipinformation-section").style.display = "none";
      // document.getElementById("content").style.marginTop = "30%"
    }
  }

  const handleTableCheckChange = () => {
    if (showTableChecked === false) {
      setShowTableChecked(true);
    } else {
      setShowTableChecked(false);
    }
  }

  const handleWhoisCheckChange = () => {
    if (showWhoisTable === false) {
      setShowWhoisTable(true);
    } else {
      setShowWhoisTable(false);
    }
  }

  function isHostname(value) {
    // check if value is an IP address
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (ipRegex.test(value)) {
      return false;
    }
  
    // check if value is a hostname 
    const hostnameRegex = /^([a-zA-Z0-9-]{1,}\.)+[a-zA-Z]{2,}$/;
    if (hostnameRegex.test(value)) {
      return true;
    }
  }

  function extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
  
    //find & remove port number
    hostname = hostname.split(':')[0];
  
    return hostname;
  }

  const [hostnameSubmitted, setHostnameSubmitted] = useState();
  const [submittedHostname, setSubmittedHostname] = useState();
  const [whosIPIsCopied, setwhosIPIsCopied] = useState();
  const [responseData, setResponseData] = useState();
  const [responseDataLast, setResponseDataLast] = useState();
  const [HTTPresponse200, setHTTPresponse200] = useState();
  const [HTTPresponseCode, setHTTPresponseCode] = useState();
  const [HTTPisLoading, setHTTPisLoading] = useState();

  let whoIsData;
  let didError = false;
  let didErrorAgain = false;
  let searchIPadd = ""
//   let toastId;
  let searchValue;
  let currIndex;
  if (previousSearches.length-1 === 0) {
    currIndex = previousSearches.length;
  } else {
    currIndex = previousSearches.length-1;
  }

  toastId = 10

  const handleURLSubmit = async (searchParam) => {
    setLoading(true);

    searchParam = searchParam.trim();
    if (searchParam.endsWith(".")) {
      searchParam = searchParam.slice(0, -1);
    };
    if (searchParam.includes('://')) {
      searchParam = extractHostname(searchParam);
    };

    searchParam = searchParam.replace(',','.')
    window.history.pushState({}, document.title, `/?search=${searchParam}`);

    toast.loading(`Looking for "${searchParam}"`,{
        id: toastId
    });

    setShowTableChecked(true)
    setShowWhoisTable(true)

    // setBrowser
    let browserInfo = navigator.userAgent;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
        setBrowser('Opera');
    } else if (browserInfo.includes('Edg')) {
        setBrowser('Microsoft Edge');
    } else if (browserInfo.includes('Chrome')) {
        setBrowser('Google Chrome');
    } else if (browserInfo.includes('Safari')) {
        setBrowser('Safari');
    } else if (browserInfo.includes('Firefox')) {
        setBrowser('Mozilla Firefox');
    } else {
        setBrowser(false);
    }
    // (try to) get OS information
    var platform = require('platform');
    if (platform.os.family === "OS X") {
        setOsFamily('macOS')
    } else {
        setOsFamily(platform.os.family)
    }

      searchParam = searchParam.trim();
      if (searchParam.endsWith(".")) {
        searchParam = searchParam.slice(0, -1);
      };
      if (searchParam.includes('://')) {
        searchParam = extractHostname(searchParam);
      };

      // -> if submitted value is not empty
      if (searchParam !== "") {
        searchValue = searchParam
        if (searchParam.includes('<') || searchParam.includes('>')) {
        //   toastId = toast.error(`Let's keep characters like "<" or ">" out of this.`)
          return
        } else {
          setSubmittedHostname(searchParam);
          previousSearches.push(searchParam);
          if (previousSearches.length === 1) {
            previousSearchesObj.shift();
          }
          var time = new Date();
          previousSearchesObj.unshift({time: time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }), domain: searchParam})
        }

        // -> if submitted value is a domain
        if (isHostname(searchParam)) {
          
            setIP(false)
            
            // request dns
            // console.log("dns request 1")
            const response = await axios.get(`${apiServer}/api/v1/dns/${searchValue}`)
            .catch(function (error) {
                if (error.response.status === 429) {
                  toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
                  })} else if (error.response.status === 404) {
                    toast.error(`Ah heck, you've exceeded the rate limit!`, {
                        id: toastId,
                  })} else {
                    toast.error(`Ah heck, we've got an error!`, {
                        id: toastId,
                  })}
                });
                
          if (response) {

            // request whois
            // console.log("whois request 1")
            const responsewhois = await axios.get(`${apiServer}/api/v1/whois/${searchValue}`)
            .catch(function (error) {
                if (error.response.status === 429) {
                  toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
                  })} else if (error.response.status === 404) {
                    toast.error(`Ah heck, you've exceeded the rate limit!`, {
                        id: toastId,
                  })} else {
                    toast.error(`Ah heck, we've got an error!`, {
                        id: toastId,
                  })}
                });
                        

            // request and set ip
            // console.log(response)
            if (response.data.length > 0 && response.data[0].record_type === "A") {
              searchIPadd = response.data[0].value

              var time3 = new Date();
              setRequestedTime(time3.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))
              
              // console.log("ip request 1")
              const searchLink = `${apiServer}/api/v1/ip/${searchIPadd}`
              let res = await axios.get(searchLink)
              .catch(function (error) {
                if (error.response.status === 429) {
                  toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
                  })} else if (error.response.status === 404) {
                    toast.error(`Ah heck, you've exceeded the rate limit!`, {
                        id: toastId,
                  })} else {
                    toast.error(`Ah heck, we've got an error!`, {
                        id: toastId,
                  })}
                });
                
                // .catch(function (error) {
                //   if (error.response.status === 404) {
                //     toast.error(`Ah heck, that's not a valid IP or hostname!`, {
                //       id: toastId,
                //     })
                //     didErrorAgain = true
                //   } else {
                //     toast.error(`Ah heck, there was an error`, {
                //       id: toastId,
                //     })
                //     didErrorAgain = true
                //   }
                // }));
              if (res || didErrorAgain !== true) {
                res = res.data
                if (isHostname(searchValue) !== true) { // fix this it should be TRUE if it is not a hostname
                  if (res.bogon === true) {
                    toast.error(`Ah heck, it looks like "${searchValue}" is a bogon`, {
                      id: toastId,
                    });
                  } else {
                    setHostnameSubmitted(false);
                    setIP(res.ip);
                    setHostname(res.hostname);
                    setOrg(res.org);
                    setCity(res.city);
                    setRegion(res.region);
                    setCountry(res.country);
                    setLoc(res.loc);
                    setLat((res.loc).split(',')[0])
                    setLong((res.loc).split(',')[1])
                    setRandomNumber(Math.floor(Math.random()*textArray.length));

                    setUserAgent(window.navigator.userAgent);
    
                    setWhosIP("the");
                    setWhosOtherStuff("the");
                  
                    setGoogleLink(`https://google.com/search?q=${res.org}`)
                    if (searchParam.includes('<') || searchParam.includes('>')) {
              
                    } else {
                      toast.success(`Found "${searchValue}"`, {
                        id: toastId,
                      })
                      document.title = `"${searchValue}" | netOf.me`
                    };
                  }
                } else {
                  setHostnameSubmitted(true);
                  setIP(res.ip);
                  setHostname(res.hostname);
                  setOrg(res.org);
                  setCity(res.city);
                  setRegion(res.region);
                  setCountry(res.country);
                  setLoc(res.loc);
                  setLat((res.loc).split(',')[0])
                  setLong((res.loc).split(',')[1])
                  setRandomNumber(Math.floor(Math.random()*textArray.length));

                  setUserAgent(window.navigator.userAgent);
                
                  setGoogleLink(`https://google.com/search?q=${res.org}`)
                  if (searchParam.includes('<') || searchParam.includes('>')) {
              
                  } else {
                    toast.success(`Found "${searchValue}"`, {
                      id: toastId,
                    })
                    document.title = `"${searchValue}" | netOf.me`
                  };
                }
              }
            }

            // set whois response
            // setWhoisResponse(responsewhois.data);

            // console.log(responsewhois)
        if (responsewhois) {

            whoIsData = responsewhois.data;
            setWhoisResponse(whoIsData);


            function convertTimeFormat(time) {
                let convertedTime = [];
                if (time.constructor === Array) {
                    time.forEach(t => {
                        let date = new Date(t);
                        let hours = date.getHours();
                        let minutes = date.getMinutes();
                        let ampm = hours >= 12 ? 'PM' : 'AM';
            
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'

                        minutes = minutes < 10 ? '0'+minutes : minutes;
            
                        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

                        let strTime = monthNames[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " " + hours + ':' + minutes + ' ' + ampm;

                        convertedTime.push(strTime);
                    }); 
                } else { 
                    let date = new Date(time);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    let ampm = hours >= 12 ? 'PM' : 'AM';
            
                    hours = hours % 12; 
                    hours = hours ? hours : 12; // the hour '0' should be '12'
            
                    minutes = minutes < 10 ? '0'+minutes : minutes;

                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

                    let strTime = monthNames[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " " + hours + ':' + minutes + ' ' + ampm;
            
                    convertedTime.push(strTime); 
                } 
            
                return convertedTime.join(' | '); 
            }

            // if whois data, set whois data
            if (whoIsData.domain_name) {
            //   var createdDate = new Date(whoIsData.creation_date * 1000);
            //   var expDate = new Date(whoIsData.expiration_date * 1000);
            //   var updatedDate = new Date(whoIsData.updated_date * 1000);

              setWhoisDomainName(whoIsData.domain_name ? whoIsData.domain_name.constructor === Array ? whoIsData.domain_name.join(' | ') : whoIsData.domain_name : whoIsData.domain_name);
              setWhoisCreationDate(convertTimeFormat(whoIsData.creation_date));
              setWhoisExpireDate(convertTimeFormat(whoIsData.expiration_date));
              setWhoisOrg(whoIsData.org ? whoIsData.org.constructor === Array ? whoIsData.org.join(' | ') : whoIsData.org : whoIsData.org);
              setWhoisUpdatedDate(convertTimeFormat(whoIsData.updated_date));
              setWhoisRegistrar(whoIsData.registrar ? whoIsData.registrar.constructor === Array ? whoIsData.registrar.join(' | ') : whoIsData.registrar: whoIsData.registrar);
              setWhoisServer(whoIsData.whois_server ? whoIsData.whois_server.constructor === Array ? whoIsData.whois_server.join(' | ') : whoIsData.whois_server : whoIsData.whois_server);

              setWhoisEmails(whoIsData.emails ? whoIsData.emails.constructor === Array ? whoIsData.emails.join(' | ') : whoIsData.emails : whoIsData.emails);
              setWhoisDNSSec(whoIsData.dnssec ? whoIsData.dnssec.constructor === Array ? whoIsData.dnssec.join(' | ') : whoIsData.dnssec : whoIsData.dnssec);
              setWhoisDomainProtection(whoIsData.state ? whoIsData.state.constructor === Array ? whoIsData.state.join(' | ') : whoIsData.state : whoIsData.state);
            }
        } else {
            setShowWhoisTable(false)
        }

            if (response.data[0]) {
              setResponseData(response.data);
              setResponseDataLast(response.data);
            }
            
            if (response && response.data && response.data[0].record_type === "A") {
              searchIPadd = response.data[0].value

              setWhosIP(searchValue + "'s");
              setDomainName(searchValue);
              setHostnameSubmitted(true);
              setSubmittedHostname(searchValue)
              setRandomNumber(Math.floor(Math.random()*textArray.length));
              setWhosOtherStuff("their");

              if (searchParam.includes('<') || searchParam.includes('>')) {
                // ignore
              } else {
                toast.success(`Found "${searchValue}"`, {
                  id: toastId,
                })
                document.title = `"${searchValue}" | netOf.me`

                setHTTPisLoading(true);
                const respo = await axios.get(`${apiServer}/api/v1/isitdown/${searchValue}`)
                .then((respo) => {
                  if (respo.data.http_response_code === 200) {
                    setHTTPresponse200(true)
                  } else {
                    setHTTPresponse200(false)
                    setHTTPresponseCode(respo.data.http_response_code)
                  }
                })
                .catch(function (error) {
                  console.log(error)
                    setHTTPresponse200(false)
                    setHTTPresponseCode("No")
                })
                setHTTPisLoading(false);
              };
            } else {   // Handle error case  
              didError = true
              toast.error(`Ah heck, we couldn't resolve "${searchValue}"`, {
                id: toastId,
              });
            }

        //   } catch (err) {   // Handle error case  
        //     console.log(err)
        //     didError = true
        //     toast.error(`Ah heck, we couldn't resolve "${searchValue}"`, {
        //       id: toastId,
        //     });
          } else {

          }

        } else {
          setDomainName(false);
          if (didError !== true && didErrorAgain !== true) {
            searchIPadd = searchValue;
            setRandomNumber(Math.floor(Math.random()*textArray.length));
          }

          setHostnameSubmitted(false);
          var time2 = new Date();
          setRequestedTime(time2.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))
          // console.log("ip request 2")
          const searchLink = `${apiServer}/api/v1/ip/${searchIPadd}`
          let res = await axios.get(searchLink)
          .catch(function (error) {
            if (error.response.status === 429) {
              toast.error(`Ah heck, you've exceeded the rate limit!`, {
                id: toastId,
              })} else if (error.response.status === 404) {
                toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
              })} else {
                toast.error(`Ah heck, we've got an error!`, {
                    id: toastId,
              })}
            });
            if (res) {
                res = res.data;
                
                if (res.bogon === true) {
                    toast.error(`Ah heck, it looks like "${searchValue}" is a bogon`, {
                        id: toastId,
                    });
                } else if (!(res.error)) {
                    setWhosIP("the");
                    setWhosOtherStuff("their");
                    setIP(res.ip);
                    setHostname(res.hostname);
                    setOrg(res.org);
                    setCity(res.city);
                    setRegion(res.region);
                    setCountry(res.country);
                    setLoc(res.loc);
                    setLat((res.loc).split(',')[0])
                    setLong((res.loc).split(',')[1])
                    setRandomNumber(Math.floor(Math.random()*textArray.length));
                    setDomainName(false);
                    setGoogleLink(`https://google.com/search?q=${res.org}`)

                    setUserAgent(window.navigator.userAgent);

                    toast.success(`Found "${searchIPadd}"`, {
                        id: toastId,
                    })
                    document.title = `"${searchValue}" | netOf.me`
                }
          } else {
            // toast.error(`Ah heck, it looks like "${searchValue}" is not valid or the rate limit has been exceeded!`, {
            //     id: toastId,
            // });
          }
        }
      } else {
        //
        // initial IP request on page load
        //

        setHostnameSubmitted(false);

        var time22 = new Date();
        setRequestedTime(time22.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))
        // ip request
        const searchLink = `${apiServer}/api/v1/ip/me`
        let res = await axios.get(searchLink)
        .catch(function (error) {
            if (error.response.status === 429) {
              toast.error(`Ah heck, you've exceeded the rate limit!`, {
                id: toastId,
              })} else if (error.response.status === 404) {
                toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
              })} else {
                toast.error(`Ah heck, we've got an error!`, {
                    id: toastId,
              })}
            });
        res = res.data;
        
        // set data
        setWhosIP("your");
        setWhosOtherStuff("your");
        setIP(res.ip);
        setHostname(res.hostname);
        setOrg(res.org);
        setCity(res.city);
        setRegion(res.region);
        setCountry(res.country);
        setLoc(res.loc);
        setLat((res.loc).split(',')[0])
        setLong((res.loc).split(',')[1])
        setRandomNumber(Math.floor(Math.random()*textArray.length));
        setDomainName(false);
        setGoogleLink(`https://google.com/search?q=${res.org}`)
        setUserAgent(window.navigator.userAgent);

      }
    setLoading(false);
}



  const handleSearchSubmit = async (e) => {
    setLoading(true);

    // up key arrowup
    if (e.keyCode === 38) {
      if (currIndex > 0) {
        currIndex = currIndex - 1;
        e.target.value = previousSearches[currIndex];
      };
    }

    // down key arrowdown
    if (e.keyCode === 40) {
      if (currIndex < previousSearches.length-1) {
        currIndex = currIndex + 1;
        e.target.value = previousSearches[currIndex];
      } else {
        e.target.value = "";
      };
    }


    // -> pressed enter
    if (e.code === 'Enter') {

      e.target.value = e.target.value.trim();
      if (e.target.value.endsWith(".")) {
        e.target.value = e.target.value.slice(0, -1);
      };
      if (e.target.value.includes('://')) {
        e.target.value = extractHostname(e.target.value);
      };

      window.history.pushState({}, document.title, `/?search=${e.target.value}`);

      // -> if submitted value is not empty
      if (e.target.value !== "") {
        searchValue = e.target.value.replace(',','.')
        e.target.value = searchValue
        window.history.pushState({}, document.title, `/?search=${searchValue}`);
        if (e.target.value.includes('<') || e.target.value.includes('>')) {
          toastId = toast.error(`Let's keep characters like "<" or ">" out of this.`)
          return
        } else {
          toastId = toast.loading(`Looking for "${searchValue}"`);
          setSubmittedHostname(e.target.value);
          previousSearches.push(e.target.value);
          if (previousSearches.length === 1) {
            previousSearchesObj.shift();
          }
          var time = new Date();
          previousSearchesObj.unshift({time: time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }), domain: e.target.value})
        }

        // -> if submitted value is a domain
        if (isHostname(e.target.value)) {
          try {
            // request dns
            // console.log("dns request 1")
            const response = await axios.get(`${apiServer}/api/v1/dns/${searchValue}`);
            

            // request whois
            // console.log("whois request 1")
            const responsewhois = await axios.get(`${apiServer}/api/v1/whois/${searchValue}`);
                        

            // request and set ip
            if (response.data[0].record_type === "A") {
              searchIPadd = response.data[0].value

              var time3 = new Date();
              setRequestedTime(time3.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))
              
              // console.log("ip request 1")
              const searchLink = `${apiServer}/api/v1/ip/${searchIPadd}`
              let res = (await axios.get(searchLink)
                .catch(function (error) {
                  if (error.response.status === 404) {
                    toast.error(`Ah heck, that's not a valid IP or hostname!`, {
                      id: toastId,
                    })
                    didErrorAgain = true
                  } else if (error.response.status === 429) {
                    toast.error(`Ah heck, you've exceeded the rate limit!`, {
                      id: toastId,
                    })
                    didErrorAgain = true
                  } else {
                    toast.error(`Ah heck, we've got an error!`, {
                        id: toastId,
                    })
                  }
                }));
              if (res || didErrorAgain !== true) {
                res = res.data
                if (isHostname(searchValue) !== true) { // fix this it should be TRUE if it is not a hostname
                  if (res.bogon === true) {
                    toast.error(`Ah heck, it looks like "${searchValue}" is a bogon`, {
                      id: toastId,
                    });
                  } else {
                    setHostnameSubmitted(false);
                    setIP(res.ip);
                    setHostname(res.hostname);
                    setOrg(res.org);
                    setCity(res.city);
                    setRegion(res.region);
                    setCountry(res.country);
                    setLoc(res.loc);
                    setLat((res.loc).split(',')[0])
                    setLong((res.loc).split(',')[1])
                    setRandomNumber(Math.floor(Math.random()*textArray.length));
    
                    setWhosIP("the");
                    setWhosOtherStuff("the");
                  
                    setGoogleLink(`https://google.com/search?q=${res.org}`)
                    if (e.target.value.includes('<') || e.target.value.includes('>')) {
              
                    } else {
                      toast.success(`Found "${searchValue}"`, {
                        id: toastId,
                      })
                      document.title = `"${searchValue}" | netOf.me`
                    };
                  }
                } else {
                  setHostnameSubmitted(true);
                  setIP(res.ip);
                  setHostname(res.hostname);
                  setOrg(res.org);
                  setCity(res.city);
                  setRegion(res.region);
                  setCountry(res.country);
                  setLoc(res.loc);
                  setLat((res.loc).split(',')[0])
                  setLong((res.loc).split(',')[1])
                  setRandomNumber(Math.floor(Math.random()*textArray.length));
                
                  setGoogleLink(`https://google.com/search?q=${res.org}`)
                  if (e.target.value.includes('<') || e.target.value.includes('>')) {
              
                  } else {
                    toast.success(`Found "${searchValue}"`, {
                      id: toastId,
                    })
                    document.title = `"${searchValue}" | netOf.me`
                  };
                }
              }
            }

            // set whois response
            // setWhoisResponse(responsewhois.data);
            whoIsData = responsewhois.data;
            setWhoisResponse(whoIsData);

            
            function convertTimeFormat(time) {
                let convertedTime = [];
                if (time.constructor === Array) {
                    time.forEach(t => {
                        let date = new Date(t);
                        let hours = date.getHours();
                        let minutes = date.getMinutes();
                        let ampm = hours >= 12 ? 'PM' : 'AM';
            
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'

                        minutes = minutes < 10 ? '0'+minutes : minutes;
            
                        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

                        let strTime = monthNames[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " " + hours + ':' + minutes + ' ' + ampm;

                        convertedTime.push(strTime);
                    }); 
                } else { 
                    let date = new Date(time);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    let ampm = hours >= 12 ? 'PM' : 'AM';
            
                    hours = hours % 12; 
                    hours = hours ? hours : 12; // the hour '0' should be '12'
            
                    minutes = minutes < 10 ? '0'+minutes : minutes;

                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

                    let strTime = monthNames[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " " + hours + ':' + minutes + ' ' + ampm;
            
                    convertedTime.push(strTime); 
                } 
            
                return convertedTime.join(' | '); 
            }

            // if whois data, set whois data
            if (whoIsData.domain_name) {
            //   var createdDate = new Date(whoIsData.creation_date * 1000);
            //   var expDate = new Date(whoIsData.expiration_date * 1000);
            //   var updatedDate = new Date(whoIsData.updated_date * 1000);

            setWhoisDomainName(whoIsData.domain_name ? whoIsData.domain_name.constructor === Array ? whoIsData.domain_name.join(' | ') : whoIsData.domain_name : whoIsData.domain_name);
            setWhoisCreationDate(convertTimeFormat(whoIsData.creation_date));
            setWhoisExpireDate(convertTimeFormat(whoIsData.expiration_date));
            setWhoisOrg(whoIsData.org ? whoIsData.org.constructor === Array ? whoIsData.org.join(' | ') : whoIsData.org : whoIsData.org);
            setWhoisUpdatedDate(convertTimeFormat(whoIsData.updated_date));
            setWhoisRegistrar(whoIsData.registrar ? whoIsData.registrar.constructor === Array ? whoIsData.registrar.join(' | ') : whoIsData.registrar: whoIsData.registrar);
            setWhoisServer(whoIsData.whois_server ? whoIsData.whois_server.constructor === Array ? whoIsData.whois_server.join(' | ') : whoIsData.whois_server : whoIsData.whois_server);

            setWhoisEmails(whoIsData.emails ? whoIsData.emails.constructor === Array ? whoIsData.emails.join(' | ') : whoIsData.emails : whoIsData.emails);
            setWhoisDNSSec(whoIsData.dnssec ? whoIsData.dnssec.constructor === Array ? whoIsData.dnssec.join(' | ') : whoIsData.dnssec : whoIsData.dnssec);
            setWhoisDomainProtection(whoIsData.state ? whoIsData.state.constructor === Array ? whoIsData.state.join(' | ') : whoIsData.state : whoIsData.state);
            }

            if (response.data[0]) {
              setResponseData(response.data);
              setResponseDataLast(response.data);
            }
            
            if (response && response.data && response.data[0].record_type === "A") {
              searchIPadd = response.data[0].value

              setWhosIP(searchValue + "'s");
              setDomainName(searchValue);
              setHostnameSubmitted(true);
              setSubmittedHostname(searchValue)
              setRandomNumber(Math.floor(Math.random()*textArray.length));
              setWhosOtherStuff("their");

              if (e.target.value.includes('<') || e.target.value.includes('>')) {
                // ignore
              } else {
                toast.success(`Found "${searchValue}"`, {
                  id: toastId,
                })
                document.title = `"${searchValue}" | netOf.me`

                setHTTPisLoading(true);
                const respo = await axios.get(`${apiServer}/api/v1/isitdown/${searchValue}`)
                .then((respo) => {
                  if (respo.data.http_response_code === 200) {
                    setHTTPresponse200(true)
                  } else {
                    setHTTPresponse200(false)
                    setHTTPresponseCode(respo.data.http_response_code)
                  }
                })
                .catch(function (error) {
                  console.log(error)
                    setHTTPresponse200(false)
                    setHTTPresponseCode("No")
                })
                setHTTPisLoading(false);
              };
            } else {   // Handle error case  
              didError = true
              toast.error(`Ah heck, we couldn't resolve "${searchValue}"`, {
                id: toastId,
              });
            }


          } catch (err) {   // Handle error case  
            didError = true
            toast.error(`Ah heck, we couldn't resolve "${searchValue}"`, {
              id: toastId,
            });
          }
        } else {
          setDomainName(false);
          if (didError !== true && didErrorAgain !== true) {
            searchIPadd = searchValue;
            setRandomNumber(Math.floor(Math.random()*textArray.length));
          }

          setHostnameSubmitted(false);
          var time2 = new Date();
          setRequestedTime(time2.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))
          // console.log("ip request 2")
          const searchLink = `${apiServer}/api/v1/ip/${searchIPadd}`
          let res = await axios.get(searchLink)
            .catch(function (error) {
              if (error.response.status === 429) {
                toast.error(`Ah heck, you've exceeded the rate limit!`, {
                  id: toastId,
                })} else if (error.response.status === 404) {
                  toast.error(`Ah heck, you've exceeded the rate limit!`, {
                      id: toastId,
                })} else {
                  toast.error(`Ah heck, we've got an error!`, {
                      id: toastId,
                })}
              });
            
            if (res) {
                res = res.data
                if (res.bogon === true) {
                  toast.error(`Ah heck, it looks like "${searchValue}" is a bogon`, {
                    id: toastId,
                  });
                } else if (!(res.error)) {
                  setWhosIP("the");
                  setWhosOtherStuff("their");
                  setIP(res.ip);
                  setHostname(res.hostname);
                  setOrg(res.org);
                  setCity(res.city);
                  setRegion(res.region);
                  setCountry(res.country);
                  setLoc(res.loc);
                  setLat((res.loc).split(',')[0])
                  setLong((res.loc).split(',')[1])
                  setRandomNumber(Math.floor(Math.random()*textArray.length));
                  setDomainName(false);
                  setGoogleLink(`https://google.com/search?q=${res.org}`)
                
                  toast.success(`Found "${searchIPadd}"`, {
                    id: toastId,
                  })
                  document.title = `"${searchValue}" | netOf.me`
                } else {
                  toast.error(`Ah heck, it looks like "${searchValue}" is not valid`, {
                      id: toastId,
                  });
                }
            }
        }
      } else {
        //
        // initial IP request on page load
        //
        document.title = "netOf.me | The public networking observability platform!"
        window.history.pushState({}, document.title, `/`);

        setHostnameSubmitted(false);
        setSubmittedHostname("");

        var time22 = new Date();
        setRequestedTime(time22.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }))
        // ip request
        const searchLink = `${apiServer}/api/v1/ip/me`
        let res = await axios.get(searchLink)
        .catch(function (error) {
            if (error.response.status === 429) {
              toast.error(`Ah heck, you've exceeded the rate limit!`, {
                id: toastId,
              })} else if (error.response.status === 404) {
                toast.error(`Ah heck, you've exceeded the rate limit!`, {
                    id: toastId,
              })} else {
                toast.error(`Ah heck, we've got an error!`, {
                    id: toastId,
              })}
            });

        if (res) {
            res = res.data

            setHostnameSubmitted(false);
            
            // set data
            setWhosIP("your");
            setWhosOtherStuff("your");
            setIP(res.ip);
            setHostname(res.hostname);
            setOrg(res.org);
            setCity(res.city);
            setRegion(res.region);
            setCountry(res.country);
            setLoc(res.loc);
            setLat((res.loc).split(',')[0])
            setLong((res.loc).split(',')[1])
            setRandomNumber(Math.floor(Math.random()*textArray.length));
            setDomainName(false);
            setGoogleLink(`https://google.com/search?q=${res.org}`)
        }
      }
    }
    setLoading(false);
  }

  const handleWantSpeedClick = () => {
    if (wantCheckSpeed === false) {
      setWantCheckSpeed(true)
      setSpeedtestURL('https://i.postimg.cc/LXCX99MZ/large-image.jpg')
    } else {
      setWantCheckSpeed(false)
      setSpeedtestURL("")
    }
  }

   // onClick handler function for the copy button
   const handleIpCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(ip)
      .then(() => {
        // If successful, update the isCopied state value
        setIpIsCopied(true);
        setTimeout(() => {
          setIpIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

     // onClick handler function for the copy button
     const handleCopyLink = () => {
        // Asynchronously call copyTextToClipboard
        let linkToCopy;
        if (hostnameSubmitted == true) {
            linkToCopy = submittedHostname
        } else {
            linkToCopy = ip
        }
        copyTextToClipboard(`${window.location.protocol + '//' + window.location.host}/?search=${linkToCopy}`)
          .then(() => {
            // If successful, update the isCopied state value
            setLinkCopied(true);
            setTimeout(() => {
              setLinkCopied(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }

     // onClick handler function for the copy button
   const handleOrgCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(org)
      .then(() => {
        // If successful, update the isCopied state value
        setOrgIsCopied(true);
        setTimeout(() => {
          setOrgIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  // const capture = async () => {
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");
  //   const video = document.createElement("video");
  
  //   try {
  //     const captureStream = await navigator.mediaDevices.getDisplayMedia();
  //     video.srcObject = captureStream;
  //     context.drawImage(video, 0, 0, window.width, window.height);
  //     const frame = canvas.toDataURL("image/png");
  //     captureStream.getTracks().forEach(track => track.stop());
  //     window.location.href = frame;
  //   } catch (err) {
  //     console.error("Error: " + err);
  //   }
  // };


  // function exportImg() {
  //     html2canvas(document.querySelector("#main-div"), {
  //       useCORS: true,
  //       allowTaint: true,
  //     }).then(canvas => {
  //     document.body.appendChild(canvas)
  //   });
  // }


  const handlewhosIPCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(submittedHostname)
      .then(() => {
        // If successful, update the isCopied state value
        setwhosIPIsCopied(true);
        setTimeout(() => {
          setwhosIPIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // const handleTableCopyClick = (copyValue) => {
  //   // Asynchronously call copyTextToClipboard
  //   copyTextToClipboard(copyValue)
  //     // .then(() => {
  //     //   // If successful, update the isCopied state value
  //     //   setwhosIPIsCopied(true);
  //     //   setTimeout(() => {
  //     //     setwhosIPIsCopied(false);
  //     //   }, 1500);
  //     // })
  //     // .catch((err) => {
  //     //   console.log(err);
  //     // });
  // }

    // onClick handler function for the copy button
   const handleMtaCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(hostname)
      .then(() => {
        // If successful, update the isCopied state value
        setMtaIsCopied(true);
        setTimeout(() => {
          setMtaIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

      // onClick handler function for the copy button
      const handleUserAgentShowClick = () => {
        // Asynchronously call copyTextToClipboard
        if (showUserAgent === true) {
          setShowUserAgent(false)
        } else {
          setShowUserAgent(true)
        }
      }

      const handleUserAgentCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(userAgent)
          .then(() => {
            // If successful, update the isCopied state value
            setUserAgentIsCopied(true);
            setTimeout(() => {
              setUserAgentIsCopied(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // const handleCopyPrevious = (data) => {
      //   // Asynchronously call copyTextToClipboard
      //   copyTextToClipboard(data)
      //     .then(() => {
      //       // If successful, update the isCopied state value
      //       setHistoryIsCopied(true);
      //       setTimeout(() => {
      //         setHistoryIsCopied(false);
      //       }, 1500);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }


      function useKey(key, cb){
        const callback = useRef(cb);
    
        useEffect(() => {
            callback.current = cb;
        })
    
        useEffect(() => {
            function handle(event){
                if(event.code === key){
                    event.preventDefault();
                    callback.current(event);
                } else if ((key === 'ctrls' && event.key === 's' && event.ctrlKey) || (key === 'ctrls' && event.key === 's' && event.metaKey)) {
                    event.preventDefault();
                    callback.current(event);
                } else if ((key === 'ctrld' && event.key === 'd' && event.ctrlKey) || (key === 'ctrld' && event.key === 'd' && event.metaKey)) {
                  event.preventDefault();
                  callback.current(event);
                  document.getElementById("toggle-dns").click()
                  // console.log('ctrld');
                } 
                else if (event.keyCode === 38) {
                  event.preventDefault();
                  if (document.getElementById("search-field")) {
                    if (document.querySelector( ':focus' ) === null) {
                      document.getElementById("search-field").focus();
                    }
                  } else {
                    document.getElementById("ip-search-icon").click();
                  }
                } else if ((key === 'ctrlx' && event.key === 'x' && event.ctrlKey) || (key === 'ctrlx' && event.key === 'x' && event.metaKey)) {
                  event.preventDefault();
                  callback.current(event);
                  document.getElementById("toggle-whois").click();
                } else if ((key === 'ctrlh' && event.key === 'h' && event.ctrlKey) || (key === 'ctrlh' && event.key === 'h' && event.metaKey)) {
                  event.preventDefault();
                  document.getElementById("history-icon").click();
                } else if ((key === 'ctrli' && event.key === 'i' && event.ctrlKey) || (key === 'ctrli' && event.key === 'i' && event.metaKey)) {
                  event.preventDefault();
                  callback.current(event);
                  document.getElementById("toggle-ip-area").click();
                } else if ((key === 'ctrlp' && event.key === 'p' && event.ctrlKey) || (key === 'ctrlp' && event.key === 'p' && event.metaKey)) {
                  event.preventDefault();
                  callback.current(event);
                  document.getElementById("toggle-ip-area").click();
                } else if ((key === 'ctrll' && event.key === 'l' && event.ctrlKey) || (key === 'ctrll' && event.key === 'l' && event.metaKey)) {
                  event.preventDefault();
                  document.getElementById("share-link-copy").click()
                } else if ((event.code === 'Backslash')) {
                  event.preventDefault();
                  document.getElementById("cli-icon").click();
                }
            }

            function ignore(e){
              if (e.code === key) {
                e.preventDefault();
              } else if (key === 'ctrls' && e.key === 's' && e.ctrlKey) {
                e.preventDefault();
              }
            }
    
            document.addEventListener('keyup',ignore);
            document.addEventListener('keydown',handle);
            return () => document.removeEventListener("keydown",handle)
        },[key])
    }
  
    useKey('ctrll');
    useKey('ctrls', () => handleSearchClick());
    useKey('ctrld', () => handleTableCheckChange());
    useKey('ctrlx', () => handleWhoisCheckChange());
    useKey('ctrlh');
    useKey('ctrli', () => handleMainIPCheckChange());
    useKey('ctrlp', () => handleMainIPCheckChange());
    // useKey('\`');

  
  const deskAnimation = (
    <svg className="deskanimation" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="study">
    <rect width="64" height="64"/>
    <g id="smoke">
    <path id="smoke-2" d="M9 21L9.55279 19.8944C9.83431 19.3314 9.83431 18.6686 9.55279 18.1056L9 17L8.44721 15.8944C8.16569 15.3314 8.16569 14.6686 8.44721 14.1056L9 13" stroke="#797270"/>
    <path id="smoke-1" d="M6.5 22L7.05279 20.8944C7.33431 20.3314 7.33431 19.6686 7.05279 19.1056L6.5 18L5.94721 16.8944C5.66569 16.3314 5.66569 15.6686 5.94721 15.1056L6.5 14" stroke="#797270"/>
    </g>
    <g id="laptop">
    <rect id="laptop-base" x="17" y="28" width="20" height="3" fill="#F3F3F3" stroke="#453F3C" stroke-width="2"/>
    <rect id="laptop-screen" x="18" y="17" width="18" height="11" fill="#5A524E" stroke="#453F3C" stroke-width="2"/>
    <rect id="line-1" x="20" y="19" width="14" height="1" fill="#F78764"/>
    <rect id="line-2" x="20" y="21" width="14" height="1" fill="#F9AB82"/>
    <rect id="line-3" x="20" y="23" width="14" height="1" fill="#F78764"/>
    <rect id="line-4" x="20" y="25" width="14" height="1" fill="#F9AB82"/>
    </g>
    <g id="cup">
    <rect id="Rectangle 978" x="5" y="24" width="5" height="7" fill="#CCC4C4" stroke="#453F3C" stroke-width="2"/>
    <path id="Ellipse 416" d="M11 28C12.1046 28 13 27.1046 13 26C13 24.8954 12.1046 24 11 24" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 996" x="6" y="25" width="3" height="1" fill="#D6D2D1"/>
    </g>
    <g id="books">
    <rect id="Rectangle 984" x="58" y="27" width="4" height="14" transform="rotate(90 58 27)" fill="#B16B4F" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 985" x="56" y="23" width="4" height="14" transform="rotate(90 56 23)" fill="#797270" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 986" x="60" y="19" width="4" height="14" transform="rotate(90 60 19)" fill="#F78764" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 993" x="47" y="20" width="12" height="1" fill="#F9AB82"/>
    <rect id="Rectangle 994" x="43" y="24" width="12" height="1" fill="#54504E"/>
    <rect id="Rectangle 995" x="45" y="28" width="12" height="1" fill="#804D39"/>
    </g>
    <g id="desk">
    <rect id="Rectangle 973" x="4" y="31" width="56" height="5" fill="#797270" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 987" x="10" y="36" width="30" height="6" fill="#797270" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 975" x="6" y="36" width="4" height="24" fill="#797270" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 974" x="40" y="36" width="18" height="24" fill="#797270" stroke="#453F3C" stroke-width="2"/>
    <line id="Line 129" x1="40" y1="48" x2="58" y2="48" stroke="#453F3C" stroke-width="2"/>
    <line id="Line 130" x1="22" y1="39" x2="28" y2="39" stroke="#453F3C" stroke-width="2"/>
    <line id="Line 142" x1="46" y1="42" x2="52" y2="42" stroke="#453F3C" stroke-width="2"/>
    <line id="Line 131" x1="46" y1="54" x2="52" y2="54" stroke="#453F3C" stroke-width="2"/>
    <rect id="Rectangle 988" x="11" y="37" width="28" height="1" fill="#54504E"/>
    <rect id="Rectangle 992" x="5" y="32" width="54" height="1" fill="#9E9492"/>
    <rect id="Rectangle 989" x="7" y="37" width="2" height="1" fill="#54504E"/>
    <rect id="Rectangle 990" x="41" y="37" width="16" height="1" fill="#54504E"/>
    <rect id="Rectangle 991" x="41" y="49" width="16" height="1" fill="#54504E"/>
    <line id="Line 143" y1="60" x2="64" y2="60" stroke="#453F3C" stroke-width="2"/>
    </g>
    </g>
    </svg>
  )

  const up = '↑'

  return (
    <>
    <div style={{margin: "0", height: "100%", overflow: "hidden"}} id="main-div">
     
     {letItSnow === true && <Snowfall color="aliceblue" snowflakeCount={30} />}

    <div className="App">
      <div id="main-head" className="header">
        <div style={{backgroundColor: "black"}}>
          <div className="header-left">
            {wantCheckSpeed ?
            <div>
              <ReactInternetSpeedMeter
                txtSubHeading="Internet connection is slow"
                outputType="" // "alert"/"modal"/"empty"
                customClassName={null}
                pingInterval={5000} // milliseconds
                txtMainHeading="Opps..."
                thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                threshold={50}
                imageUrl={speedtestURL}
                downloadSize="3210664" //bytes
                callbackFunctionOnNetworkDown={(data) =>
                  console.log(`Internet speed : ${data}`)
                }
                callbackFunctionOnNetworkTest={(data) => 
                  setCheckSpeed(data)
                }
              />
              <div className="card-body mt-4">
                <span style={{cursor: "pointer"}} onClick={() => (window.open(`/`,'_parent'))} id="neon-span-small">netOf.me</span><sup style={{fontSize: "14px", color: "#2b3a61", paddingRight: "13px"}}><i>BETA</i></sup>
                  <i data-tooltip-id="tooltip" data-tooltip-content="Stop speedtest" onClick={refresh} style={{cursor: "pointer"}} className="fa fa-regular fa-circle-stop"></i>
                <span style={{paddingLeft: "7px"}} className="display-1">{checkSpeed} MB/s</span>
              </div>
            </div>
            : checkSpeed ? (
            <div>
              <HeaderLogo
                showMainIPChecked={showMainIPChecked}
                showTableChecked={showTableChecked}
                showWhoisTable={showWhoisTable}
              />
              <i data-tooltip-id="tooltip" data-tooltip-content="Perform a basic looping speedtest" onClick={refresh} style={{cursor: "pointer", paddingLeft: "7px"}} className="fa fa-solid fa-gauge-high"></i>
              <span className="display-1">{checkSpeed} MB/s</span>
            </div>
            ) : (
            <div>
              <HeaderLogo
                showMainIPChecked={showMainIPChecked}
                showTableChecked={showTableChecked}
                showWhoisTable={showWhoisTable}
              />
              <i data-tooltip-id="tooltip" data-tooltip-content="Perform a basic looping speedtest" onClick={handleWantSpeedClick} style={{cursor: "pointer"}} className="fa fa-solid fa-gauge-high"></i>
              {/* <i data-tooltip-id="tooltip" onClick={exportImg} data-tooltip-content="Save screenshot of page" className="fa-solid fa-image" style={{cursor: "pointer", paddingLeft: "13px"}}></i> */}
              <span>
                <span style={{paddingLeft: "13px"}}>
                  <ConsolePopup apiServer={apiServer} />
                </span>

                <span style={{paddingLeft: "13px"}}>
                  <i id="ip-search-icon" data-tooltip-id="tooltip" data-tooltip-content={"Search an IP address or hostname (CTRL (or ⌘) + S or ARROW UP)"} style={{cursor: "pointer"}} onClick={handleSearchClick} className="fa-solid fa-magnifying-glass"></i>
                </span>
                
                {showSearch && 
                <>
                  <span>
                    <span style={{paddingLeft: "8px"}}>
                      <input id="search-field" spellCheck={false} onKeyDown={handleSearchSubmit} className="input" placeholder="IP, domain name or URL" autoComplete='off' autoFocus></input>
                    </span>

                    <HistoryDropdown
                      previousSearches={previousSearches}
                      previousSearchesObj={previousSearchesObj}
                    />

                    {domainName && 
                    <>
                      <span style={{paddingLeft: "13px"}}>
                        <ExternalLinkPopupFAIcon
                          triggerId="crt-sh-icon"
                          triggerFAiconClass="fa-solid fa-certificate"
                          tooltipDescription="Show SSL/TLS certificate history on crt.sh"
                          popupPositionArray={['bottom center']}
                          onActionArray={['click']}
                          externalLink={`https://crt.sh/?q=${submittedHostname}`}
                        />
                      </span>
                    </>}
                  </span>


                  <span style={{paddingLeft: "11px"}}>
                    <HeaderToggle
                      wrapperId="toggle-ip-area"
                      toggleId="main-ip-area"
                      currentCheck={showMainIPChecked}
                      onChange={handleMainIPCheckChange}
                      text="IP"
                      description="Show main IP information module (CTRL + I)"
                    />
                  </span>
                  <span style={{paddingLeft: "61px"}}>
                    <HeaderToggle
                      wrapperId="toggle-dns"
                      toggleId="cheese-status"
                      currentCheck={showTableChecked}
                      onChange={handleTableCheckChange}
                      text="DNS"
                      description="Show DNS records module (CTRL + D)"
                    />
                  </span>
                  <span style={{paddingLeft: "61px"}}>
                    <HeaderToggle
                      wrapperId="toggle-whois"
                      toggleId="whois-status"
                      currentCheck={showWhoisTable}
                      onChange={handleWhoisCheckChange}
                      text="WHOIS"
                      description="Show WHOIS data module (CTRL + X)"
                    />
                  </span>
                </>}
              </span>
            </div>)}        
          </div>

        {/* <Infocon /> */}
      
        <Toaster
            toastOptions={{
              success: {
                style: {
                  background: '#19AA27',
                  color: "aliceblue",
                  fontSize: "16px",
                  // marginTop: "70px",
                  zIndex: "99999999999999999999"
                },
              },
              error: {
                duration: 4500,
                style: {
                  background: "#ff2e2e",
                  color: "aliceblue",
                  fontSize: "16px",
                  // marginTop: "70px",
                },
              },
              loading: {
                style: {
                  background: "aliceblue",
                  fontSize: "16px",
                  // marginTop: "70px",
                }
              }
            }}
        />
        <header>
        {/* <PulsingBadge variant="success" withBorder badgeLabel="Pagato">ooooooooooOooo</PulsingBadge> */}
          {requesedTime && <span style={{cursor: "default"}} id="last-refreshed-time">{requesedTime}</span>}
          {loading ? <i data-tooltip-id="tooltip" data-tooltip-content="Refresh" onClick={refresh} style={{cursor: "pointer", paddingLeft: "13px", paddingRight: "13px", animation: "rotation infinite 3s"}} className="fa-solid fa-arrows-rotate"></i> : <i data-tooltip-id="tooltip" data-tooltip-content="Refresh" onClick={refresh} style={{cursor: "pointer", paddingLeft: "13px", paddingRight: "13px"}} className="fa-solid fa-arrows-rotate"></i>}
          {linkCopied ? <span style={{paddingRight: "13px"}}><i data-tooltip-id="tooltip" data-tooltip-content="Copied link to clipboard!" className="fa-solid fa-check"></i></span> : <span style={{paddingRight: "13px"}}><i id="share-link-copy" style={{cursor: "pointer"}} data-tooltip-id="tooltip" data-tooltip-content="Copy link to clipboard (CTRL + L)" onClick={handleCopyLink} className="fa-solid fa-link"></i></span>}
          {loading ? <i data-tooltip-id="tooltip" data-tooltip-content="netOf.me Info" onClick={openInfoModal} style={{cursor: "pointer"}} className="fa fa-regular fa-info-circle"></i> : <i data-tooltip-id="tooltip" data-tooltip-content="netOf.me Info" onClick={openInfoModal} style={{cursor: "pointer"}} className="fa fa-regular fa-info-circle"></i>}
        </header>
      </div>
    </div>
    <FadeIn delay={225} duration={1100}>
      <div className="content-wrapper">
      <div className="content">
      
      <div id="ipinformation-section">
        <FadeIn>
          {ip !== false ? 
            hostnameSubmitted ? 
                whosIPIsCopied ? 
                    HTTPresponse200 ?
                    // <i onClick={() => (window.open(`http://${submittedHostname}`, '_blank'))} data-tooltip-id="tooltip-top" data-tooltip-content={"200 HTTP Response"} style={{color: "#e8bb2f", fontSize: "17px", cursor: "pointer"}} class="fa-regular fa-circle"></i>
                        HTTPisLoading ? <span className="head-text">{textArray[randomNumber]} <span className="http-res-loader"></span> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copied to clipboard!" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> : <span className="head-text">{textArray[randomNumber]} <i onClick={() => (window.open(`http://${submittedHostname}`, '_blank'))} data-tooltip-id="tooltip-top" data-tooltip-content={"200 HTTP Response"} style={{color: "#19AB27", fontSize: "17px", cursor: "pointer"}} className="fa-solid fa-circle-check"></i> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copied to clipboard!" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> 
                    : 
                    // <i data-tooltip-id="tooltip-top" data-tooltip-content={`${HTTPresponseCode} HTTP Response`} style={{color: "#e8bb2f", fontSize: "17px"}} className="fa-regular fa-circle"></i>
                        HTTPisLoading ? <span className="head-text">{textArray[randomNumber]} <span className="http-res-loader"></span> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copied to clipboard!" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span>  : <span className="head-text">{textArray[randomNumber]} <i data-tooltip-id="tooltip-top" data-tooltip-content={`${HTTPresponseCode} HTTP Response`} style={{color: "#EF1932", fontSize: "17px"}} className="fa-solid fa-circle-xmark"></i> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copied to clipboard!" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> 
                : 
                    HTTPresponse200 ?
                        HTTPisLoading ? <span className="head-text">{textArray[randomNumber]} <span className="http-res-loader"></span> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copy to clipboard" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> : <span className="head-text">{textArray[randomNumber]} <i onClick={() => (window.open(`http://${submittedHostname}`, '_blank'))} data-tooltip-id="tooltip-top" data-tooltip-content={"200 HTTP Response"} style={{color: "#19AB27", fontSize: "17px", cursor: "pointer"}} className="fa-solid fa-circle-check"></i> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copy to clipboard" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> 
                    : 
                        HTTPisLoading ? <span className="head-text">{textArray[randomNumber]} <span className="http-res-loader"></span> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copy to clipboard" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> : <span className="head-text">{textArray[randomNumber]} <i data-tooltip-id="tooltip-top" data-tooltip-content={`${HTTPresponseCode} HTTP Response`} style={{color: "#EF1932", fontSize: "17px"}} className="fa-solid fa-circle-xmark"></i> <span data-tooltip-id="tooltip-top" data-tooltip-content="Copy to clipboard" onClick={handlewhosIPCopyClick} style={{cursor: "pointer"}}>{whosIP}</span> IP address is:</span> 
            : 
                <span className="head-text">{textArray[randomNumber]} {whosIP} IP address is:</span>
            : <></>
          }
          <br/>
          <span id="click-me-ip" className="ip-address">{ip}</span>
          <span onClick={handleIpCopyClick}>
            <span style={{fontSize: "22px", paddingLeft: "7px", color: "#222e4c", cursor: "pointer"}}>{isIpCopied ? (<i data-tooltip-id="tooltip" data-tooltip-content="Copied to clipboard!" className="fa fa-regular fa-check"></i>) : ip && (<i data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i>)}</span>
          </span>
          <br/>
          {ip && <span style={{fontSize: "18px", color: "#222e4c"}}>It's that {up}</span>}
          <br/>
          <br/>
          {(org || hostname) && <span style={{fontSize: "16px", color: "#222e4c"}}>..and {whosOtherStuff} other stuff is:</span>}
          {org && (
          <div>
            <span style={{fontSize: "20px", color: "aliceblue"}}>{org}</span>
            <span onClick={handleOrgCopyClick}>
              <span style={{fontSize: "16px", paddingLeft: "7px", color: "#222e4c", cursor: "pointer"}}>
                {isOrgCopied ? (<i data-tooltip-id="tooltip" data-tooltip-content="Copied to clipboard!" className="fa fa-regular fa-check"></i>) : org && (<i data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i>)}
                {lat && 
                (<span style={{paddingLeft: "7px"}}>
                  <Popup
                    trigger={
                      <i id="google-link" data-tooltip-id="tooltip" data-tooltip-content="Search on Google" style={{ color: "#222e4c", fontSize: "17px", cursor: "pointer"}} className="fa-brands fa-google"></i>
                    }
                    position={['bottom center']}
                    on={['click']}
                  >
                    <ExternalLinkConfirm 
                      link={googleLink} 
                      id={'google-link'}
                    />
                  </Popup>
                </span>)}
              </span>
            </span>
          </div>)}


          {hostname && (
          <div>
            <span style={{fontSize: "20px", color: "aliceblue"}}>
              {hostname}
            </span>
            <span onClick={handleMtaCopyClick}>
              <span style={{fontSize: "16px", paddingLeft: "7px", color: "#222e4c", cursor: "pointer"}}>
                {isMtaCopied ? (<i data-tooltip-id="tooltip" data-tooltip-content="Copied to clipboard!" className="fa fa-regular fa-check"></i>) : hostname && (<i data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i>)}
              </span>
            </span>
          </div>)}


          {ip && (
          <div>
            <span style={{fontSize: "20px", color: "aliceblue"}}>
              {city && (<span>{city}</span>)}{region && (<span>, {region}</span>)}{country && (<span>, {country}</span>)}
              {lat && (<span style={{paddingLeft: "7px"}}>
              <ReactCountryFlag
                countryCode={country}
                svg
                style={{
                  width: '1em',
                  height: '1em',
                  paddingBottom: '5px',
                  paddingRight: '8px',
                }}
                // cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                // cdnSuffix="svg"
                title={country}
              />

              <i data-tooltip-id="tooltip" data-tooltip-content="View on map" onClick={openMapModal} style={{color: "#222e4c", fontSize: "17px", cursor: "pointer"}} className="fa fa-regular fa-map-location-dot"></i></span>)}
            </span>
          </div>)}


          <div>
            <span style={{fontSize: "15px", color: "#222e4c"}}>
              {userAgent && (showUserAgent ? 
              <div>
                <FadeIn delay={175} duration={750}>
                  <span style={{color: "aliceblue"}}>
                    {userAgent}
                  </span>
                  <span onClick={handleUserAgentCopyClick} style={{paddingLeft: "7px", cursor: "pointer"}}>
                    {isUserAgentCopied ? (<i data-tooltip-id="tooltip" data-tooltip-content="Copied to clipboard!" className="fa fa-regular fa-check"></i>) : (<i data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i>)}
                  </span>
                  <span onClick={handleUserAgentShowClick} style={{paddingLeft: "7px", cursor: "pointer"}}>
                    <i data-tooltip-id="tooltip" data-tooltip-content="Hide User Agent" className="fa fa-thin fa-eye-slash"></i>
                  </span>
                </FadeIn>
              </div> 
              : 
              ip &&
              <FadeIn delay={0} duration={450}>
                <div>
                  <span>{browser && browser}{osFamily && " on " + osFamily}</span><span style={{paddingLeft: "7px"}}><i onClick={handleUserAgentShowClick} data-tooltip-id="tooltip" data-tooltip-content="Show User Agent" style={{cursor: "pointer"}} className="fa fa-thin fa-eye"></i></span>
                </div>
              </FadeIn>)}
            </span>
          </div>
        </FadeIn>
      </div>




              {showMainIPChecked !== true && showTableChecked !== true && showWhoisTable !== true ? 
              <>
                <FadeIn delay={30} duration={350}>
                  <div style={{width: "100%", height: "100%"}}>
                    <div style={{top: "50%", paddingBottom: "100px", paddingTop: "30px"}}>
                      <span style={{fontSize: "32px", color: "#222e4c"}}>
                        Ah heck, no modules are enabled!
                      </span>
                      <p style={{color: "#222e4c"}}>It's tough to see anything if nothing is enabled...</p>
                      {/* <p style={{color: "#222e4c"}}><i>"The only thing worse than being blind is having sight but no vision." - Hellen Keller</i></p> */}
                    </div>
                    <span id="neon-span-large" spellCheck="false">
                      netOf.me
                    </span>
                    {/* {deskAnimation} */}
                  </div>
                </FadeIn>
              </>
              : <></>}


        
          {hostnameSubmitted ? showTableChecked ?
          <div style={{paddingTop: "20px", display: "inline-flex", paddingRight: "5px"}}>
            <FadeIn delay={10} duration={550}>
              <div style={{paddingBottom: "35px"}}>
                <h2>DNS records</h2>
                <table className="">
                  <tbody>
                    <tr>
                        <th><i>Type</i></th>
                        <th><i>Data</i></th>
                    </tr>

                    {responseData ? responseData.map((record, index) => (
                      <tr key={index} data-index={index}>
                        <td>{record.record_type}</td>
                        {record.record_type === "MX" ? <td className="data-dt">{record.value} | Priority: {record.priority}</td> 
                          : 
                         record.record_type === "SOA" ? <td className="data-dt">MName: {record.mname} | RName: {record.rname}</td>
                          : 
                          <td className="data-dt">{record.value}</td>}
                        {/* {record.record_type == "SOA" ? <td>MName: {record.mname} | RName: {record.rname}</td> : <td>{record.value}</td>} */}
                        {/* <td>{record.value}</td> */}
                        {/* <td id={`${index}`}>{record.value}</td> <i onClick={handleTableCopyClick(document.getElementsByTagName("td")[index])} data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i> */}
                        {/* <td>{record.priority}</td> */}
                        {/* <td>{record.mname}</td>
                        <td>{record.rname}</td> */}
                      </tr>
                    )) 
                    :
                    responseDataLast.map((record, index) => (
                      <tr key={index} data-index={index}>
                        {/* {console.log(index)} */}
                        <td>{record.record_type}</td>
                        {record.record_type === "MX" ? <td className="data-dt">{record.value} | Priority: {record.priority}</td> 
                          : 
                         record.record_type === "SOA" ? <td className="data-dt">MName: {record.mname} | RName: {record.rname}</td>
                          : 
                          <td className="data-dt">{record.value}</td>}
                        {/* {record.record_type == "SOA" ? <td>MName: {record.mname} | RName: {record.rname}</td> : <td>{record.value}</td>} */}
                        {/* <td>{record.value}</td> */}
                        {/* <td id={`${index}`}>{record.value}</td> <i onClick={handleTableCopyClick(document.getElementsByTagName("td")[index])} data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i> */}
                        {/* <td>{record.priority}</td> */}
                        {/* <td>{record.mname}</td>
                        <td>{record.rname}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </FadeIn>
          </div>
         : <div></div> : <div></div>}


            {hostnameSubmitted ? whoisResponse ? showWhoisTable ?
            <div style={{paddingTop: "20px", display: "inline-flex", paddingLeft: "5px"}}>
              <FadeIn delay={10} duration={550}>
                <div style={{paddingBottom: "35px"}}>
                  <h2>Whois Data</h2>
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <i>Type</i>
                        </th>
                        <th>
                          <i>Data</i>
                        </th>
                      </tr>

                      {/* {whoisResponse.map((record, index) => (
                        <tr key={index}>
                          <td>{record}</td>
                          <td>{record[index]}</td>
                        </tr>
                      ))} */}

                      {/* {whoIsData.forEach(function(result){
                          console.log(result)
                      })} */}
                
                      {whoisDomainName &&
                      <tr key={0}>
                        <td className="data-dt">Domain Name</td>
                        <td className="data-dt">{whoisDomainName}</td>
                      </tr>}
                      
                      {whoisCreationDate && whoisCreationDate !== "Invalid Date" &&
                      <tr key={1}>
                        <td className="data-dt">Creation Date</td>
                        <td className="data-dt">{whoisCreationDate}</td>
                      </tr>}
                      
                      {whoisUpdatedDate && whoisUpdatedDate !== "Invalid Date" &&
                      <tr key={2}>
                        <td className="data-dt">Updated Date</td>
                        <td className="data-dt">{whoisUpdatedDate}</td>
                      </tr>}
                      
                      {whoisExpireDate && whoisExpireDate !== "Invalid Date" &&
                      <tr key={3}>
                        <td className="data-dt">Expire Date</td>
                        <td className="data-dt">{whoisExpireDate}</td>
                      </tr>}
                      
                      {whoisOrg &&                 
                      <tr key={4}>
                        <td className="data-dt">Org</td>
                        <td className="data-dt">{whoisOrg}</td>
                      </tr>}
                      
                      {whoisRegistrar &&
                      <tr key={5}>
                        <td className="data-dt">Registrar</td>
                        <td className="data-dt">{whoisRegistrar}</td>
                      </tr>}
                      
                      {whoisServer &&
                      <tr key={6}>
                        <td className="data-dt">Whois Server</td>
                        <td className="data-dt">{whoisServer}</td>
                      </tr>}
                      
                      {whoisEmails &&
                      <tr key={7}>
                        <td className="data-dt">Contact Emails</td>
                        <td className="data-dt">{whoisEmails}</td>
                      </tr>}
                      
                      {whoisDNSSec && 
                      <tr key={8}>
                        <td className="data-dt">DNSSEC</td>
                        <td className="data-dt">{whoisDNSSec}</td>
                      </tr>}
                      
                      {whoisDomainProtection &&
                      <tr key={9}>
                        <td className="data-dt">Domain Protection</td>
                        <td className="data-dt">{whoisDomainProtection}</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
              </FadeIn>
            </div>
            : 
            <div></div>
            : 
            <div></div>
            :
            <div></div>
            }
          </div>
        </div>
      </FadeIn>





      <Modal
        isOpen={extraModelIsOpen}
        onAfterOpen={afteropenMapModal}
        onRequestClose={closeExtraModal}
        style={customStylesMap}
        contentLabel="What"
        ariaHideApp={false}
      >
        <header>
          <i onClick={closeExtraModal} style={{cursor: "pointer", paddingRight: "5px"}} className="fa fa-solid fa-times"></i>
        </header>
        <h1 style={{display: "initial"}}>{city && (<span>{city}</span>)}{region && (<span>, {region}</span>)}{country && (<span>, {country}</span>)}</h1>
        <span style={{paddingLeft: "0px"}}>
          <ReactCountryFlag
                  countryCode={country}
                  svg
                  style={{
                    width: '3.75em',
                    height: '2em',
                    paddingBottom: '13px',
                  }}
                  // cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  // cdnSuffix="svg"
                  title={country}
          />
        </span>

        {loading ? <span className="loader"></span> :
        <div id="map">
          <MapContainer center={[lat, long]} zoom={10} scrollWheelZoom={true}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='OpenStreetMap contributors'
              className="map-tiles"
            />
            <Marker position={[lat, long]}>
              {/* <Popup>
                {city && (<span>{city}</span>)}{region && (<span>, {region}</span>)}{country && (<span>, {country}</span>)}
                <br/>
                Lat: {lat}, Long: {long}
                <br/>
                <br/>
                {ip}
                <br/>
                {hostname}
                <br/>
                {org}
              </Popup> */}
            </Marker>
          </MapContainer>
        </div>}

        <div style={{float: "left", fontSize: "12px"}}>
          <span>IP location is usually only accurate to down a city.</span>
        </div>
        <div style={{float: "right", fontSize: "25px"}}>
          <span style={{paddingRight: "7px", fontSize: "22px"}}>
            <ExternalLinkPopupFAIcon
              triggerId="google-maps-pin-icon"
              triggerFAiconClass="fa-solid fa-location-dot"
              tooltipDescription="Open in Google Maps"
              popupPositionArray={['bottom center']}
              onActionArray={['click']}
              externalLink={`https://www.google.com/maps/@?api=1&map_action=map&center=${lat}%2C${long}1&zoom=14&basemap=satellite`}
            />

            </span><span>Lat: {lat}, Long: {long}</span>
        </div>
      </Modal>

    
      {/* {infoModelIsOpen ? <TabModal /> : <></>} */}



      <Modal
        isOpen={infoModelIsOpen}
        onAfterOpen={afteropenInfoModal}
        onRequestClose={closeInfoModal}
        style={customStyles}
        contentLabel="What"
        ariaHideApp={false}
      >

        <header>
          <i onClick={closeInfoModal} style={{cursor: "pointer", paddingRight: "5px"}} className="fa fa-solid fa-times"></i>
        </header>

        <Tabs>
          <TabList>
            <Tab>Why</Tab>
            <Tab>Uptime</Tab>
            <Tab>Privacy</Tab>
            <Tab>Data/Cookies</Tab>
            <Tab>Support</Tab>
            <Tab>Params/API</Tab>
            {/* <Tab>Settings</Tab> */}
            {/* <Tab>Changelog</Tab> */}
          </TabList>


          <TabPanel>
            <h1 style={{display: "initial"}}>Why<span id="neon-span" spellCheck="false">netOf.me</span>?</h1>
            <br/>
            <div className="scroll">
              <p>netOf.me was started because there are too many ads on many of the IP reference websites out there (<i>cough... IPChicken</i>).</p> 
              <p>A great deal of them are old, outdated and don't employ modern web frameworks (<i>cough cough... IPChicken</i>).</p>
              <p>We wanted a quick one stop corner shop for basic public networking reference needs, but we aren't stopping there. While we are constantly changing things and adding more features, we hope you continue to love netOf.me for it's dynamic simplicity as much as we do.</p>
              <h2>AdBlockers</h2>
              <h3>You use an adblocker!</h3>
              <p>"I already use an adblocker! So I won't benefit from the ad-free nature of this site anyways!". Sure you use an AdBlocker — so do I. This site wasn't created to only be used on <i>your</i> computer. Don't be so selfish.</p>
              <h2>What else?</h2>
              <p>Check out 
                <Popup
                  trigger={
                    <span id="mha-link-span"> <a data-tooltip-id="tooltip" data-tooltip-content="A modern email header analyzer with a similar style and design" style={{textDecoration: "underline", cursor: "pointer"}}>MailHeaderAnalyzer.com</a> </span>
                  }
                  position={['bottom center']}
                  on={['click']}
                >
                  <ExternalLinkConfirm 
                    link={'https://mailheaderanalyzer.com'} 
                    id={'mha-link-span'}
                  />
                </Popup>
                for a modern email header analysis tool.</p>
            </div>
          </TabPanel>


          <TabPanel>
            {/* <h2 style={{display: "initial"}}>Uptime</h2> */}
            <div className="scroll">
              <p>The main web application operates on a handful of high performance and high availability nodes in the US among three different cloud providers. On top of that, there are additional strategic hard failover sites as well as local failover sites.</p>
              {/* <h1 style={{display: "initial"}}>Availability</h1> */}
              {/* <div className="space-even-spans"> */}
                <div>
                <div className="space-even-spans" style={{overflowY: "hidden", paddingBottom: "20px"}}>
                  <div style={{textAlign: "end", paddingRight: "13px", width: "47%"}}>
                    <span className="ErrorCounter" onClick={() => (window.open(`https://status.netof.me`,'_blank'))} style={{cursor: "pointer"}}></span>
                  </div>
                  <div style={{textAlign: "initial", width: "63%"}}>
                    <span data-tooltip-id="tooltip" data-tooltip-content="Open status page" className="online-span" onClick={() => (window.open(`https://status.netof.me`,'_blank'))}  style={{fontSize: "32px", fontWeight: "900", cursor: "pointer"}}>ONLINE</span>
                  </div>
                </div>
                {/* </div> */}
                <div style={{width: "100%", justifyContent: "center", display: "inline-flex"}}>
                  <p>24 hours a day, 365 days a year, netOf.me maintains:</p>
                </div>
                <div style={{width: "100%", justifyContent: "center", display: "inline-flex"}}>
                  <ul className="dashed"><li><span style={{fontWeight: "900"}}>99.99%</span> gateway/application availability</li><li><span style={{fontWeight: "900"}}>98%</span> availability of each of our individual nodes</li></ul>
                </div>
              </div>
              <p>We like to be online - like all the time. That's why netOf.me was built from the ground up with redundancy front of mind.</p>
              <p>With the exception of a few technical aspects, netOf.me is essentially a client side application. The lack of necessity to persist server-side data makes scaling a TON easier.</p>
              <p>For a more accurate representation of our current availability, view our <a href="https://status.netof.me" target={"_blank"} rel={"noreferrer"}>service status</a>.</p>
            </div>
          </TabPanel>


          <TabPanel>
            <div className="scroll">
              <PrivacyPolicy />
            </div>
          </TabPanel>


          <TabPanel>
            <div className="scroll"> 
              <CookiePolicy />
            </div>
          </TabPanel>


          <TabPanel>
            <div style={{display: "flex", justifyContent: "center"}}>
              <Popup
                trigger={
                  <h1 style={{cursor: "pointer"}} id="buy-us-header">Buy us a coffee!</h1>
                }
                position={['bottom center']}
                on={['click']}
              >
                <ExternalLinkConfirm 
                  link={'https://www.buymeacoffee.com/netOf.me'} 
                  id={'buy-us-header'}
                />
              </Popup>
            </div>
              
            <div style={{display: "flex", justifyContent: "center", paddingBottom: "40px"}}>
              <Popup
                trigger={
                  <span style={{cursor: "pointer"}} id="coffee" className="loader"></span>
                }
                position={['bottom center']}
                on={['click']}
              >
                <ExternalLinkConfirm 
                  link={'https://www.buymeacoffee.com/netOf.me'} 
                  id={'coffee'}
                />
              </Popup>
            </div>
              
            <hr></hr>
              
            <div style={{display: "", textAlign: "center", paddingBottom: "20px"}}>
              <h2 style={{paddingTop: "20px"}}>You can help!</h2>
              <p>Send an email to: dev (at) netOf [dot] me</p>
              <p>Submit bug reports and feature requests 
                <Popup
                  trigger={
                    <span id="feedback-here-span"> <a style={{textDecoration: "underline", cursor: "pointer"}}>here</a>.</span>
                  }
                  position={['top center']}
                  on={['click']}
                >
                  <ExternalLinkConfirm 
                    link={'https://feedback.smorin.dev'} 
                    id="feedback-here-span"
                  />
                </Popup>
              </p>
            </div>
          </TabPanel>
    
    
          {/* <TabPanel>
            <SettingsPanel />
          </TabPanel> */}


          {/* <TabPanel>
            <div>
              <h1 style={{display: "initial"}}>Changelog</h1>
              <p>Changeloging not active yet!</p>
              <p>Please reference the <a href="https://feedback.smorin.dev/boards/3" target={"_blank"} rel={"noreferrer"}>feedback system</a>.</p>
            </div>
          </TabPanel> */}

          <TabPanel>
            <div className="scroll">
            <div style={{display: "flex", justifyContent: "center"}}>
                <h1>URL Search Parameter (non-API)</h1>
            </div>
            <p>A URL search parameter can be supplied to netOf.me's URL. When navigating to the URL in a browser, netOf.me will search the IP or domain supplied to the search URL parameter instead of the browser's current public IP.</p>
            <p>You can always copy a parameter-ized link of the current result to your clipboard using the link button in the top right corner (or CTRL + L).</p>
            <p>Anytime a URL search parameter is used, DNS and WHOIS information will be shown in addition to IP information.</p>
            <div>
                <span><b>Examples:</b></span>
                <br/>
                <code style={{backgroundColor: "#0F1629"}}>
                    {window.location.protocol + '//' + window.location.host}/?search=cloudflare.com 
                    <span style={{paddingLeft: "7px"}}>           
                        <i id="open-url-param-example1" data-tooltip-id="tooltip" data-tooltip-content="Open in new tab" onClick={() => (window.open(`${window.location.protocol + '//' + window.location.host}/?search=cloudflare.com`,"_blank"))} style={{ fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-square-arrow-up-right"></i> 
                    </span>
                    <br/>
                    {window.location.protocol + '//' + window.location.host}/?search=104.16.133.229 
                    <span style={{paddingLeft: "7px"}}>
                        <i id="open-url-param-example1" data-tooltip-id="tooltip" data-tooltip-content="Open in new tab" onClick={() => (window.open(`${window.location.protocol + '//' + window.location.host}/?search=104.16.133.229`,"_blank"))} style={{ fontSize: "18px", cursor: "pointer"}} className="fa-solid fa-square-arrow-up-right"></i>
                    </span>
                </code>
                <br/>
            </div>
            <br/>
            <hr></hr>
            <div style={{display: "flex", justifyContent: "center"}}>
                <h1>Public API</h1>
            </div>
            <p>netOf.me provides a rate-limited public API for programatic querying. The API does not require authentication. The API has a strict cross-orgin resource sharing policy - the API cannot be called directly in frontend websites, it must be called from a backend or direct query to url in browser. The rate of requests must fit the following criteria otherwise a <b>"429 - TOO MANY REQUESTS"</b> HTTP response will be returned.</p> 
            <br/>
            <span style={{fontSize: "20px"}}><b>Request rate limits:</b></span>
            <br/>
            <p>20 per minute, 150 per hour, and 500 per day</p>
            <p>Please allow at least 3 seconds between requests.</p>
            <p>Any requests received while limiting is active will result in dynamically longer lockout windows.</p>
            <p><i>Need more capacity? Contact us at more-api(at)netof[dot]me</i></p>
            <div style={{display: "flex", justifyContent: "center"}}>
                <h1>API Endpoints</h1>
            </div>
            <p>There are currently 5 main endpoints, each for the following data: IP info, DNS records, WHOIS data, and TLS/SSL certificate history, site online status (200 HTTP response) (Is It Down?)</p>
            <br/>
            <span style={{fontSize: "24px"}}><b>IP info:</b></span>
            <br/>
            <br/>
            <span><b>Get current IP only:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/ip
            </code>
            <br/>
            <br/>
            <span><b>Get current IP and return associated info:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/ip/me
            </code>
            <br/>
            <br/>
            <span><b>Supply an IP to search and return associated info:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/ip/{`<ip address>`}
            </code>
            <br/>
            <br/>
            <div>
                <span><b>Examples:</b></span>
                <br/>
                <code style={{backgroundColor: "#0F1629"}}>
                    {apiServer}/api/v1/ip/138.1.33.162
                    <br/>
                    {apiServer}/api/v1/ip/oracle.com
                </code>
                <br/>
                <br/>
                <span><b>Response:</b></span>
                <div style={{width:"100%", backgroundColor: "#0F1629", borderRadius: "6px"}}>
                <pre><code>
{`{
    "city": "Phoenix",
    "country": "US",
    "hostname": "ocomtld-prod.appoci.oracle.com",
    "ip": "138.1.33.162",
    "loc": "33.4665,-111.9984",
    "org": "AS31898 Oracle Corporation",
    "postal": "85008",
    "region": "Arizona",
    "timezone": "America/Phoenix"
}`}
                </code></pre>
                </div>
            </div>
            <br/>
            <hr></hr>
            <br/>
            <span style={{fontSize: "24px"}}><b>DNS records:</b></span>
            <br/>
            <br/>
            <span><b>Get only an A record of a supplied domain:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/dns/a/{`<domain>`}
            </code>
            <br/>
            <br/>
            <span><b>Return all available DNS records for a supplied domain:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/dns/{`<domain>`}
            </code>
            <br/>
            <br/>
            <div>
                <span><b>Example:</b></span>
                <br/>
                <code style={{backgroundColor: "#0F1629"}}>
                    {apiServer}/api/v1/dns/example.com
                </code>
                <br/>
                <br/>
                <span><b>Response:</b></span>
                <div style={{width:"100%", backgroundColor: "#0F1629", borderRadius: "6px"}}>
                <pre><code>
{`[
    {
        "record_type": "A",
        "value": "93.184.216.34"
    },
    {
        "record_type": "AAAA",
        "value": "2606:2800:220:1:248:1893:25c8:1946"
    },
    {
        "priority": 0,
        "record_type": "MX",
        "value": "."
    },
    {
        "record_type": "NS",
        "value": "a.iana-servers.net."
    },
    {
        "record_type": "NS",
        "value": "b.iana-servers.net."
    },
    {
        "expire": 1209600,
        "mname": "ns.icann.org.",
        "record_type": "SOA",
        "refresh": 7200,
        "retry": 3600,
        "rname": "noc.dns.icann.org.",
        "serial": 2022091310,
        "ttl": 3600
    },
    {
        "record_type": "TXT",
        "value": "wgyf8z8cgvm2qmxpnbnldrcltvk4xqfn"
    },
    {
        "record_type": "TXT",
        "value": "v=spf1 -all"
    }
]`}
                </code></pre>
                </div>
            </div>
            <br/>
            <hr></hr>
            <br/>
            <span style={{fontSize: "24px"}}><b>WHOIS data:</b></span>
            <br/>
            <br/>
            <span><b>Get WHOIS data for a supplied domain:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/whois/{`<domain>`}
            </code>
            <br/>
            <br/>
            <div>
                <span><b>Example:</b></span>
                <br/>
                <code style={{backgroundColor: "#0F1629"}}>
                    {apiServer}/api/v1/whois/oracle.com
                </code>
                <br/>
                <br/>
                <span><b>Response:</b></span>
                <div style={{width:"100%", backgroundColor: "#0F1629", borderRadius: "6px"}}>
                <pre><code>
{`{
    "address": "500 Oracle Parkway M/S 501ip3,",
    "city": "Redwood Shores",
    "country": "US",
    "creation_date": [
        "Fri, 02 Dec 1988 05:00:00 GMT",
        "Fri, 02 Dec 1988 05:00:00 GMT"
    ],
    "dnssec": "unsigned",
    "domain_name": [
        "ORACLE.COM",
        "oracle.com"
    ],
    "emails": [
        "abusecomplaints@markmonitor.com",
        "domain-contact_ww_grp@oracle.com",
        "network-contact_ww_grp@oracle.com",
        "whoisrequest@markmonitor.com"
    ],
    "expiration_date": [
        "Fri, 01 Dec 2023 05:00:00 GMT",
        "Fri, 01 Dec 2023 00:00:00 GMT"
    ],
    "name": "Domain Administrator",
    "name_servers": [
        "A1-160.AKAM.NET",
        "A11-66.AKAM.NET",
        "A13-65.AKAM.NET",
        "A18-67.AKAM.NET",
        "NS1.P04.DYNECT.NET",
        "NS2.P04.DYNECT.NET",
        "NS3.P04.DYNECT.NET",
        "NS4.P04.DYNECT.NET",
        "ns2.p04.dynect.net",
        "a18-67.akam.net",
        "a11-66.akam.net",
        "ns3.p04.dynect.net",
        "ns4.p04.dynect.net",
        "a13-65.akam.net",
        "ns1.p04.dynect.net",
        "a1-160.akam.net"
    ],
    "org": "Oracle Corporation",
    "referral_url": null,
    "registrant_postal_code": "94065",
    "registrar": "MarkMonitor, Inc.",
    "state": "CA",
    "updated_date": [
        "Sun, 30 Oct 2022 09:19:41 GMT",
        "Fri, 04 Nov 2022 21:11:20 GMT"
    ],
    "whois_server": "whois.markmonitor.com"
}`}
                </code></pre>
                </div>
            </div>
            <br/>
            <hr></hr>
            <br/>
            <span style={{fontSize: "24px"}}><b>TLS/SSL Certificate history:</b></span>
            <br/>
            <br/>
            <span><b>Get TLS/SSL certificate history for a supplied domain:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/crt/{`<domain>`}
            </code>
            <br/>
            <br/>
            <div>
                <span><b>Example:</b></span>
                <br/>
                <code style={{backgroundColor: "#0F1629"}}>
                    {apiServer}/api/v1/crt/example.com
                </code>
                <br/>
                <br/>
                <span><b>Response:</b></span>
                <div style={{width:"100%", backgroundColor: "#0F1629", borderRadius: "6px"}}>
                <pre><code>
{`[
    {
        "common_name": "www.example.org",
        "entry_timestamp": "2023-03-17T16:26:38.016",
        "id": 8913351873,
        "issuer_ca_id": 185756,
        "issuer_name": "C=US, O=DigiCert Inc",
        "name_value": "example.com
        www.example.com",
        "not_after": "2024-02-13T23:59:59",
        "not_before": "2023-01-13T00:00:00",
        "serial_number": "0c1fcb184518c7e3866741236d6b73f1"
    },
    {
        "common_name": "www.example.org",
        "entry_timestamp": "2023-01-13T13:18:21.987",
        "id": 8396709327,
        "issuer_ca_id": 185756,
        "issuer_name": "C=US, O=DigiCert Inc",
        "name_value": "example.com
        www.example.com",
        "not_after": "2024-02-13T23:59:59",
        "not_before": "2023-01-13T00:00:00",
        "serial_number": "0c1fcb184518c7e3866741236d6b73f1"
    },
    {
        "common_name": "example.com",
        "entry_timestamp": null,
        "id": 8506962125,
        "issuer_ca_id": -1,
        "issuer_name": "Issuer Not Found",
        "name_value": "example.com
        user@example.com",
        "not_after": "2033-01-24T01:21:18",
        "not_before": "2023-01-27T01:21:18",
        "serial_number": "1ac1e693c87d36563a92ca145c87bbcc"
    }
]`}
                </code></pre>
                </div>
            </div>
            <br/>
            <hr></hr>
            <br/>
            <span style={{fontSize: "24px"}}><b>Check website online status (Is It Down?):</b></span>
            <br/>
            <br/>
            <span><b>Get online status for a supplied domain. 200 response = false, else = true:</b></span>
            <br/>
            <code style={{backgroundColor: "#0F1629"}}>
                {apiServer}/api/v1/isitdown/{`<domain>`}
            </code>
            <br/>
            <br/>
            <div>
                <span><b>Example:</b></span>
                <br/>
                <code style={{backgroundColor: "#0F1629"}}>
                    {apiServer}/api/v1/isitdown/cloudflare.com
                </code>
                <br/>
            </div>
            <br/>
            <span><b>Response:</b></span>
            <div style={{width:"100%", backgroundColor: "#0F1629", borderRadius: "6px"}}>
                <pre><code>
{`{
    "isitdown": false, 
    "http_response_code": 200
}`}
                </code></pre>
            </div>

            </div>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          Made with ❤️ by <a style={{color: "#222e4c"}} href="/">netOf.me</a>
        </footer>
      </Modal>



      <Footer />
      </div>
    
        {/* <CookieConsent
            location="bottom"
            style={{ 
              background: "#222e4c" 
            }}
            buttonStyle={{ 
              color: "black", 
              fontSize: "16px", 
              borderRadius: "4.5px",
              backgroundColor: "aliceblue"
            }}
            expires={30}
        >
          We use cookies to enhance your user experience. If you don't like that.. I'm sorry.
        </CookieConsent> */}

        <Tooltip style={{ backgroundColor: "rgb(239, 248, 255, 1)", color: "#0F1629", padding: "3px 5px 3px 5px", zIndex: "9999999999999999" }} anchorSelect="#last-refreshed-time" content="Last updated" place="top" />
        <Tooltip id="tooltip" style={{ zIndex: "999999999999999999999", backgroundColor: "rgb(239, 248, 255, 1)", color: "#0F1629", padding: "3px 5px 3px 5px" }} place="bottom" />
        <Tooltip id="tooltip-top" style={{ zIndex: "99999999999999999999", backgroundColor: "rgb(239, 248, 255, 1)", color: "#0F1629", padding: "3px 5px 3px 5px" }} place="top" />
      </div>
    </>
  );
}

export default App;