import { useState } from "react"

export default function ClearMyCookies() {
    const [cleared, setCleared] = useState();

    const clearCookies = () => {
        localStorage.clear()
        setCleared(true)
        setTimeout(() => setCleared(false), 10000)
    }

    return (
        <center style={{paddingTop: "10px", paddingBottom: "10px"}}>
            <div style={{
                    width: "fit-content", 
                    borderRadius: "7px", 
                    padding: "12px",
                    cursor: "pointer"
                }} 
                data-tooltip-id="tooltip" 
                data-tooltip-content="Clear this site's local browser storage."
                className="button-hover-cookies"
                onClick={clearCookies}
            >
                <i style={{
                        cursor: "pointer", 
                        paddingRight: "10px", 
                        fontSize: "22px"
                    }} 
                    onClick={clearCookies} 
                    className="fa fa-solid fa-cookie-bite"
                ></i>
                <span style={{
                        cursor: "pointer", 
                        fontSize: "22px"
                    }} 
                    onClick={clearCookies}
                >Clear my data now</span>
                <i style={{
                    cursor: "pointer", 
                    paddingLeft: "10px", 
                    fontSize: "22px"
                }} 
                onClick={clearCookies} className="fa fa-solid fa-trash"></i>
            </div>

            {cleared ? <span style={{color: "#138D15"}}>The cookies are gone! You can close this tab (or <a style={{color: "#138D15"}} href="/">refresh</a>).</span> : <span style={{color: "#2b3a61"}}><i>We can trash those cookies.</i></span>}
        </center>
    )
};