import { useLocalStorage } from "../../useLocalStorage";

export default function HeaderLogo(props) {
    // showMainIPChecked, showTableChecked, showWhoisTable
    const [stopFlicker, setStopFlicker] = useLocalStorage("stopFlicker",false);
  
    const toggleFlicker = () => {
      stopFlicker ? setStopFlicker(false) : setStopFlicker(true)
    }
  
    return (
      <>
        { stopFlicker ? <><span data-tooltip-id="tooltip" data-tooltip-content="Turn the light back on" style={{cursor: "pointer", animation: "none", fontWeight: "900"}} onClick={toggleFlicker} id="neon-span-small">netOf.me</span><sup style={{fontSize: "14px", color: "#2b3a61", paddingRight: "13px"}}><i>BETA</i></sup></>
          : props.showMainIPChecked !== true && props.showTableChecked !== true && props.showWhoisTable !== true ? <><span data-tooltip-id="tooltip" data-tooltip-content="Lights out!" id="neon-span-small-dark">netOf.me</span><sup style={{fontSize: "14px", color: "#2b3a61", paddingRight: "13px"}}><i>BETA</i></sup></>
             : <><span data-tooltip-id="tooltip" data-tooltip-content="Turn that flickering light off!" style={{cursor: "pointer"}} onClick={toggleFlicker} id="neon-span-small">netOf.me</span><sup style={{fontSize: "14px", color: "#2b3a61", paddingRight: "13px"}}><i>BETA</i></sup></>}
      </>
    )
  }