import Toggle from "react-toggle";

export default function HeaderToggle(props) {
    return (
      // wrapperId, toggleId, currentCheck, onChange, text, description
      <span id={props.wrapperId} style={{top: "0", paddingTop: "13px", paddingBottom: "0px", position: "absolute"}} data-tooltip-id="tooltip" data-tooltip-content={`${props.description}`}>
        <Toggle
          id={props.toggleId}
          checked={props.currentCheck}
          onChange={props.onChange} 
        />
        {props.currentCheck ? 
        <p style={{fontSize: "13px", padding: "0px", margin: "-7px", fontWeight: "900", letterSpacing: "1.25px", color: "#19AB27"}}>{props.text}</p> 
        : 
        <p style={{fontSize: "13px", padding: "0px", margin: "-7px", fontWeight: "900", letterSpacing: "1.25px"}}>{props.text}</p>}
      </span>
    )
  }