import { useState, useEffect, useRef } from "react";

export default function ExternalLinkConfirm(props) {
    // link, id
    const [isLinkCopied, setIsLinkCopied] = useState();
  
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    const handleCopyLinkClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(props.link)
        .then(() => {
          // If successful, update the isCopied state value
          setIsLinkCopied(true);
          setTimeout(() => {
            setIsLinkCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    function useKey(key, cb){
      const callback = useRef(cb);
      useEffect(() => {
          callback.current = cb;
      })
      useEffect(() => {
          function handle(event){
              if(event.code === key){
                  event.preventDefault();
                  callback.current(event);
              } else if ((event.code === 'Enter')) {
                  event.preventDefault();
                  // callback.current(event);
                  window.open(`${props.link}`,'_blank')
              }
          }
  
          document.addEventListener('keydown',handle);
          return () => document.removeEventListener("keydown",handle)
      },[key])
    }
  
    useKey('');
  
    return (
      <div>
        <div style={{maxWidth: "850px", paddingTop: "10px"}} id={"history-table-wrapper"}>
          <div style={{textAlign: "center", padding: "7px"}}>
            Open external link to:
            <br/>
            <br/>
            <code style={{backgroundColor: "#222e4c"}}>{props.link}</code>
            <span style={{paddingLeft: "7px"}}>
              {isLinkCopied ? <i style={{cursor: "pointer", color: "#2b3a61"}} data-tooltip-id="tooltip" data-tooltip-content="Copied to clipboard!" className="fa fa-regular fa-check"></i> 
              : <i onClick={handleCopyLinkClick} style={{cursor: "pointer", color: "#2b3a61"}} data-tooltip-id="tooltip" data-tooltip-content="Copy to clipboard" className="fa fa-regular fa-copy"></i>
              }
            </span>
            <br/>
          </div>
          <hr className="hr-popup"></hr>
          <div style={{textAlign: "center", paddingBottom: "7px", paddingTop: "7px", fontSize: "16px"}}>
            Are you sure?
          </div>
          <div style={{textAlign: "center", paddingBottom: "7px"}}>
            <span className="openCheckmarkSpanWrap"><span className="openCheckmarkSpan" style={{color: "#19AB27", cursor: "pointer"}} onClick={() => (window.open(`${props.link}`,'_blank') & (document.getElementById(`${props.id}`).click()))}><i style={{cursor: "pointer"}} className="fa fa-solid fa-check"></i></span></span>
            <span className="closeCheckmarkSpanWrap"><span className="closeCheckmarkSpan" style={{cursor: "pointer"}} onClick={() => (document.getElementById(`${props.id}`).click())}><i style={{cursor: "pointer"}} className="fa fa-solid fa-x"></i></span></span>
          </div>
        </div>
      </div>
    )
  }