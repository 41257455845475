import Popup from "reactjs-popup";
import ExternalLinkConfirm from "./ExternalLinkConfirm";

export default function ExternalLinkPopupFAIcon(props) {
    // triggerId, tooltipDescription, popupPositionArray, onActionArray, externalLink
    return (
      <Popup
        trigger={
          <i id={props.triggerId} data-tooltip-id="tooltip" data-tooltip-content={props.tooltipDescription} style={{cursor: "pointer"}} className={props.triggerFAiconClass}></i>
        }
        position={props.popupPositionArray}
        on={props.onActionArray}
      >
        <ExternalLinkConfirm 
          link={props.externalLink} 
          id={props.triggerId}
        />
      </Popup>
    )
};