import { useState, useRef } from "react";
import Popup from 'reactjs-popup';
import Terminal from "react-console-emulator";
import axios from "axios";

export default function ConsolePopup(props) {
    const [loading, setLoading] = useState(false);

    const consoleEndRef = useRef(null);
    const terminal = useRef(null);

    let searchValue;

    let response;
    let responsewhois;

    function isHostname(value) {
        // if value is an IP address
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (ipRegex.test(value)) {
          return false;
        }
    
        // if value is a hostname 
        const hostnameRegex = /^([a-zA-Z0-9-]{1,}\.)+[a-zA-Z]{2,}$/;
        if (hostnameRegex.test(value)) {
          return true;
        }
    }

    const handleCrtConsoleSubmit = async (submitted) => {
        submitted = submitted.split(' ')[1]
        if (isHostname(submitted)) {
            response = (await axios.get(`${props.apiServer}/api/v1/crt/${submitted}`)
            .catch(function (error) {
                if (error) {
                    terminal.current.pushToStdout('Failed to get subdomains... Check your query and try again!')
                }
            }))

            return (JSON.stringify(response.data, undefined, 4))
        }
    }

    const handleWhoisConsoleSubmit = async (submitted) => {
        setLoading(true);
        if (submitted !== "") {
            submitted = submitted.split(' ')[1];
            searchValue = submitted

            if (isHostname(searchValue)) {
                responsewhois = await axios.get(`${props.apiServer}/api/v1/whois/${searchValue}`);
                // {headers: {'X-Api-Key': 'TEtVaKM4w4jbzEywoc9auw==Q96tXhuupuv9ZC3N'}}

                if (responsewhois.data) {
                    return JSON.stringify(responsewhois.data, undefined, 4);
                } else {
                    terminal.current.pushToStdout('WHOIS lookup failed... Check your query and try again!')
                }
            }
        }
    }

    const handleDnsConsoleSubmit = async (submitted) => {
        setLoading(true);
        if (submitted !== "") {
            submitted = submitted.split(' ')[1];
            searchValue = submitted

            response = (await axios.get(`${props.apiServer}/api/v1/dns/${searchValue}`)
            .catch(function (error) {
                if (error) {
                    terminal.current.pushToStdout('Failed to get IP from domain... Check your query and try again!')
                }
            }))

            setLoading(false);
            return JSON.stringify(response.data, undefined, 4)
        }
    }

    const handleIpConsoleSubmit = async (submitted) => {
        // setLoading(true);
        // terminal.current.pushToStdout('Running...')

        if (submitted !== "") {
            searchValue = submitted.split(' ')[1];

            if (isHostname(searchValue)) {
                // if hostname submitted, get ip from first A record if any present
                if (isHostname(searchValue)) {
                    response = (await axios.get(`${props.apiServer}/api/v1/dns/a/${searchValue}`)
                    .catch(function (error) {
                        if (error) {
                            terminal.current.pushToStdout('Failed to get IP from domain... Check your query and try again!')
                        }
                    }))
                    if (response.data) {
                        searchValue = response.data.ip
                    }
                }

                // ip lookup after get dns A record
                const searchLink = `${props.apiServer}/api/v1/ip/${searchValue}`;
                let res = (await axios.get(searchLink)
                    .catch(function (error) {
                    if (error.response.status === 404) {
                        terminal.current.pushToStdout('We got nothing... Check your query and try again!')
                    } else {
                        terminal.current.pushToStdout('We got an error on that... Check your query and try again!')
                    }}));
                if (res.data) {
                    setLoading(false);
                    return (JSON.stringify(res.data, undefined, 4));
                }
            } else {
                // ip lookup
                const searchLink = `${props.apiServer}/api/v1/ip/${searchValue}`;
                let res = (await axios.get(searchLink)
                  .catch(function (error) {
                    if (error.response.status === 404) {
                        terminal.current.pushToStdout('IP lookup failed... Check your query and try again!')
                    } else {
                        terminal.current.pushToStdout('We got an error on that... Check your query and try again!')
                    }
                  }));
                if (res.data) {
                    setLoading(false);
                    return JSON.stringify(res.data, undefined, 4);
                }
            }
        }
    }


    const commands = {
      // help: {
      //     description: 'Ehellllppppp',
      //     usage: 'help',
      //     fn: function () {
      //         return `${Array.from(commands).map((command, index) => `${command.usage} - ${command.description}`)}`
      //     }
      // },
      exit: {
          description: 'Close this popup!',
          usage: 'exit',
          fn: function () {
              document.getElementById('cli-icon').click()
          }
      },
      echo: {
          description: 'Echo a passed string.',
          usage: 'echo <string>',
          fn: function () {
              return `${Array.from(arguments).join(' ')}`
          }
      },
      ip: {
          description: 'Lookup IP (or domain)',
          usage: 'ip <ip or domain>',
          fn: function () {  
              // const res = handleConsoleSubmit(`ip ${Array.from(arguments).join(' ')}`)
              // setTimeout(() => terminal.current.pushToStdout(JSON.stringify(res, undefined, 4)), 1500)
              // return 'Running, please wait...'
              // terminal.current.pushToStdout()
              return (handleIpConsoleSubmit(`ip ${Array.from(arguments).join(' ')}`))
              // return ("loadin yoo")
          }
      },
      whois: {
          description: 'WHOIS lookup',
          usage: 'whois <domain>',
          fn: function () {
              return (handleWhoisConsoleSubmit(`whois ${Array.from(arguments).join(' ')}`))
          }
      },
      dns: {
          description: "DNS record lookup",
          usage: 'dns <domain>',
          fn: function () {
              return (handleDnsConsoleSubmit(`dns ${Array.from(arguments).join(' ')}`))
          }
      },
      crt: {
          description: "SSL certificate/subdomain lookup",
          usage: 'crt <domain>',
          fn: function () {
              return (handleCrtConsoleSubmit(`crt ${Array.from(arguments).join(' ')}`))
          }
      },
    }


    return (
        <Popup
          trigger={
            <i id="cli-icon" style={{cursor: "pointer"}} data-tooltip-id="tooltip" data-tooltip-content="Open web console ('\')" className="fa-solid fa-terminal"></i>
          }
          position={['bottom left']}
          closeOnDocumentClick
          modal={true}
          lockScroll={true}
          overlayStyle={{
            backgroundColor: 'rgb(15, 22, 41, 0.965)',
            zIndex: 9999999,
          }}
        >
            <div className="scroll-console" style={{minWidth: "1000px", maxWidth: "1000px", height: "600px"}}>
                {loading ? <span class="loader-basic"></span> : <span></span>}
                <Terminal
                  ref={terminal}
                  commands={commands}
                  noAutoScroll={false}
                  // disableOnProcess={true}
                  // hidePromptWhenDisabled={true}
                  // autoFocus={true}
                  styleEchoBack={'labelOnly'}
                  noDefaults={false}
                  // overlayStyle={{
                  //   backgroundColor: "black"
                  // }}
                  style={{
                    backgroundColor: "black",
                    overflowAnchor: "none",
                    minHeight: "100%",
                  }}
                  welcomeMessage={["Ah heck, welcome to the netOf.me web console!","Use this to return raw JSON responses and additional data.","Use 'help' to view commands."]}
                  promptLabel={"me@netOf:~$"}
                  promptLabelStyle={{
                  // color: "#2b3a61",
                    color: "#138D15",
                    fontSize: "15px",
                  // fontWeight: "900",
                  }}
                  inputTextStyle={{
                    color: "aliceblue",
                    fontSize: "15px",
                  }}
                  contentStyle={{
                    fontSize: "15px",
                    overflow: "wrap",
                  }}
                />
                <div ref={consoleEndRef} style={{overflowAnchor: "auto"}} />
            </div>
        </Popup>
    )
}